import { isImpersonating }          from 'util/impersonation';
import { isAdmin, isHolding }       from 'util/userMethods';
import EconomicConnector            from './connectors/EconomicConnector';
import DineroConnector              from './connectors/DineroConnector';
import BillyConnector               from './connectors/BillyConnector';
import UnicontaConnector            from './connectors/UnicontaConnector';
import DynaccountConnector          from './connectors/DynaccountConnector';
import WebFinanceConnector          from './connectors/WebFinanceConnector';
import XenaConnector                from './connectors/XenaConnector';
import BusinessCentralConnector     from './connectors/BusinessCentralConnector';
import OfindaConnector              from './connectors/OfindaConnector';
import ManualConnector              from './connectors/ManualConnector';
import SwedishSIEConnector          from './connectors/SwedishSIEConnector';

import { BUSINESSCENTRAL_ERP, SWEDISH_SIE_ERP } from 'flags';

import logo_economic         from 'images/erp/economicslim.svg';
import logo_dinero           from 'images/erp/dinero.svg';
import logo_billy            from 'images/erp/billy.svg';
import logo_uniconta         from 'images/erp/uniconta.svg';
import logo_dynaccount       from 'images/erp/dynaccountslim.png';
import logo_webfinance       from 'images/erp/webfinance.png';
import logo_xena             from 'images/erp/xena.svg';
import logo_businesscentral  from 'images/erp/businesscentral.png';
import logo_ofinda           from 'images/erp/ofinda.png';
// const logo_manual           = require('images/erp/manual.svg');
// const logo_swedish_sie      = require('images/erp/swedish-sie.png');

// TODO: ERP-system data should be served from server
// maybe from "erp-broker"?
export const erpIdentifiers = {
    economic: 'economic',
    dinero: 'dinero',
    billy: 'billy',
    uniconta: 'uniconta',
    dynaccount: 'dynaccount',
    webfinance: 'webfinance',
    xena: 'xena',
    businesscentral: 'businesscentral',
    ofinda: 'ofinda',
    manual: 'manual',
    swedishSIE: 'swedishSIE',
};

const erpSystems = {
    /* E-conomic */
    [erpIdentifiers.economic]: {
        id: erpIdentifiers.economic,
        name: 'Economic',
        connectTitle: 'Economic',
        isBookkeepingSystem: true,
        isEligible: null,
        logo: logo_economic,
        Connector: EconomicConnector,
        supportsClosingSheet: true,
        allowChoosingJournalForBooking: true,
        supportsFetchingAttachmentsFromBookedEntries: true,
        signUpLink: 'https://www.e-conomic.dk/demo',
    },
    /* Dinero */
    [erpIdentifiers.dinero]: {
        id: erpIdentifiers.dinero,
        name: 'Dinero',
        connectTitle: 'Dinero',
        isBookkeepingSystem: true,
        isEligible: null,
        logo: logo_dinero,
        Connector: DineroConnector,
        supportsClosingSheet: true,
        allowChoosingJournalForBooking: false,
        supportsFetchingAttachmentsFromBookedEntries: false,
        signUpLink: 'https://dinero.dk/#opret-konto',
    },

    /* Billy */
    [erpIdentifiers.billy]: {
        id: erpIdentifiers.billy,
        name: 'Billy',
        connectTitle: 'Billy',
        isBookkeepingSystem: true,
        isEligible: null,
        logo: logo_billy,
        Connector: BillyConnector,
        supportsClosingSheet: true,
        allowChoosingJournalForBooking: true,
        supportsFetchingAttachmentsFromBookedEntries: false,
        signUpLink: 'https://www.billy.dk/signup/',
    },

    /* Uniconta */
    [erpIdentifiers.uniconta]: {
        id: erpIdentifiers.uniconta,
        name: 'Uniconta',
        connectTitle: 'Uniconta',
        isBookkeepingSystem: true,
        isEligible: null,
        logo: logo_uniconta,
        Connector: UnicontaConnector,
        supportsClosingSheet: true,
        allowChoosingJournalForBooking: false,
        supportsFetchingAttachmentsFromBookedEntries: false,
        signUpLink: 'https://www.uniconta.com/da/registrer-dig-nu/',
    },

    /* Dynaccount */
    [erpIdentifiers.dynaccount]: {
        id: erpIdentifiers.dynaccount,
        name: 'Dynaccount',
        connectTitle: 'Dynaccount',
        isBookkeepingSystem: true,
        isEligible: null,
        logo: logo_dynaccount,
        Connector: DynaccountConnector,
        supportsClosingSheet: false,
        allowChoosingJournalForBooking: false,
        supportsFetchingAttachmentsFromBookedEntries: false,
        signUpLink: 'https://dynaccount.dk/',
    },

    /* WebFinance */
    [erpIdentifiers.webfinance]: {
        id: erpIdentifiers.webfinance,
        name: 'WebFinance',
        connectTitle: 'WebFinance',
        isBookkeepingSystem: true,
        isEligible: () => true,
        logo: logo_webfinance,
        Connector: WebFinanceConnector,
        supportsClosingSheet: false,
        allowChoosingJournalForBooking: false,
        supportsFetchingAttachmentsFromBookedEntries: false,
        signUpLink: 'https://webfinance.dk/gratis-demo/',
    },

    /* Xena */
    [erpIdentifiers.xena]: {
        id: erpIdentifiers.xena,
        name: 'Xena',
        connectTitle: 'Xena',
        isBookkeepingSystem: true,
        isEligible: () => true,
        logo: logo_xena,
        Connector: XenaConnector,
        supportsClosingSheet: false,
        allowChoosingJournalForBooking: false,
        supportsFetchingAttachmentsFromBookedEntries: false,
        signUpLink: 'https://login.xena.biz/Account/Register',
    },

    /* Business Central */
    [erpIdentifiers.businesscentral]: {
        id: erpIdentifiers.businesscentral,
        name: 'Business Central',
        connectTitle: 'Business Central',
        isBookkeepingSystem: true,
        isEligible: user => isAdmin(user) || BUSINESSCENTRAL_ERP(),
        logo: logo_businesscentral,
        Connector: BusinessCentralConnector,
        supportsClosingSheet: false,
        allowChoosingJournalForBooking: false,
        supportsFetchingAttachmentsFromBookedEntries: false,
        signUpLink: 'https://www.microsoft.com/da-dk/dynamics-365/products/business-central',
    },

    /* Ofinda */
    [erpIdentifiers.ofinda]: {
        id: erpIdentifiers.ofinda,
        name: 'Ofinda',
        connectTitle: 'Ofinda',
        isBookkeepingSystem: true,
        isEligible: () => true,
        logo: logo_ofinda,
        Connector: OfindaConnector,
        supportsClosingSheet: false,
        allowChoosingJournalForBooking: false,
        supportsFetchingAttachmentsFromBookedEntries: false,
        signUpLink: 'https://www.ofinda.dk/wizard.htm',
    },

    /* Manual Accounting */
    [erpIdentifiers.manual]: {
        id: erpIdentifiers.manual,
        name: 'Manuel bogføring',
        connectTitle: 'Jeg har ikke et bogføringssystem',
        isBookkeepingSystem: false,
        isEligible: user => isHolding(user) || isImpersonating(),
        logo: null,
        Connector: ManualConnector,
        supportsClosingSheet: false,
        allowChoosingJournalForBooking: false,
        supportsFetchingAttachmentsFromBookedEntries: false,
    },

    /* Swedish accounting */
    [erpIdentifiers.swedishSIE]: {
        id: erpIdentifiers.swedishSIE,
        name: 'Svenskt SIE-format',
        connectTitle: 'SIE-format import',
        isBookkeepingSystem: false,
        isEligible: user => isAdmin(user) || SWEDISH_SIE_ERP(),
        logo: null,
        Connector: SwedishSIEConnector,
        supportsClosingSheet: false,
        allowChoosingJournalForBooking: false,
        supportsFetchingAttachmentsFromBookedEntries: false,
    },
};

export default erpSystems;
