import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Menu, Grid, Button, Message, Loader } from 'semantic-ui-react';
import compose from 'lodash.flowright';

import i18n from 'i18n/pages/AppLauncher';
import withUserData from 'util/withUserData';
import { isImpersonating } from 'util/impersonation';
import { getOwnedTaxYears, isAllowedToViewProduct, isErhverv, isSelskab } from 'util/userMethods';
import { compileMarkdown } from 'util/markdown';
import { STARTER_HELP_ID } from 'design/pages/Payment/PaymentFlowStarterHelp';
import ColoredText from 'design/atoms/ColoredText';
import PageHeader from 'design/atoms/PageHeader';

import TaxYearNew from './components/TaxYearNew';
import ProductPage from '../ProductPage';
import productStatuses from './productStatuses';
import StarterHelp from './StarterHelp';

import styles from './AppLauncher.module.scss';
import RenderOwnerShipTree from 'design/molecules/OwnerShipTree';
import { JOINT_TAX } from 'flags';

class AppLauncher extends Component {
    static propTypes = {
        product: PropTypes.object,
        taxYears: PropTypes.arrayOf(PropTypes.shape({
            variations: PropTypes.array,
            year: PropTypes.number,
        })),
    };

    static MIN_TAX_YEARS = 3;

    constructor (props) {
        super(props);
        this.state = this.prepareInitialState();
    }

    prepareInitialState = () => {
        // prepare list of tax years
        const taxYears = this.prepareTaxYears(this.props.taxYears);

        // initialize state w/ minimum properties to render the scene
        const state = {
            missingRequiredRole: false,
            activeMenuItem: this.defaultActiveMenuItem(taxYears),
            taxYears,
        };

        // if there are relevant tax years to show
        // => decide how many to initially show in the menu
        if (state.taxYears.length > 0) {
            // find tax year flagged as "highlighted"
            const highlightedTaxYear = this.findHighlightedTaxYear(taxYears);

            // get latest tax year
            const latest = taxYears[0].year;

            // find difference between latest and highlighted tax year
            const taxYearDiff = latest - highlightedTaxYear;

            // decide initial amount of tax year menu items shown
            state.taxYearsToShow = Math.min(
                Math.max(
                    taxYearDiff + 1,
                    AppLauncher.MIN_TAX_YEARS,
                ),
                taxYears.length,
            );
        }
        
        return state;
    };

    /**
     * Determines which tax years are shown in the laucher
     * @param {*} taxYears 
     */
    prepareTaxYears = taxYears => {
        const { userData, product } = this.props;

        const out = [];

        // prepare tax years from props
        for (let taxYear of taxYears) {
            // skip: expired tax years
            const expired = taxYear.variations.every(v => v.expired);
            if (expired) {
                continue;
            }
            // skip: tax years after dissolved date
            const dissolved = taxYear.variations.every(v => v.dissolved);
            if (dissolved) {
                continue;
            }

            // skip: future tax years (but we want teasers to show)
            const future = taxYear.variations.every(v => v.future);
            const teaser = taxYear.variations.every(v => v.teaser);
            if (future && !teaser) {
                continue;
            }

            // show the rest of the tax years in the launcher
            out.push(taxYear);
        }

        // get latest supported tax year
        const latestSupportedTaxYear = taxYears[0] && taxYears[0].year;

        // prepare purchased tax years, that lies in the future
        const ownedTaxYears = getOwnedTaxYears(userData, product.id);

        // sort ascending
        ownedTaxYears.sort((a, b) => {
            const an = Number(a);
            const bn = Number(b);

            return an - bn;
        });

        for (let ownedTaxYear of ownedTaxYears) {
            // convert to number
            const taxYear = Number(ownedTaxYear);

            // check if already done
            if (taxYear <= latestSupportedTaxYear) {
                continue;
            }

            // unshift empty tax year
            out.unshift({
                year: ownedTaxYear,
                variations: [],
            });
        }

        return out;
    };

    consumeHash = () => {
        const { hash } = window.location;
        if (!hash) {
            return;
        }

        // reset hash
        window.location.hash = '';

        return hash.substring(1);
    };

    componentDidMount = () => {
        const { product, userData } = this.props;
        const missingRequiredRole = !isAllowedToViewProduct(userData, product);
        this.setState({ missingRequiredRole });
    };

    componentDidUpdate = (prevProps) => {
        const hash = this.consumeHash();
        if (!prevProps.location.hash && hash) {
            this.setState({ activeMenuItem: hash });
        }
    };

    defaultActiveMenuItem = taxYears => {
        const hash = this.consumeHash();
        if (hash) {
            if (isNaN(hash)) {
                return hash;
            }
            return Number(hash);
        } else {
            return this.findHighlightedTaxYear(taxYears);
        }
    };

    findHighlightedTaxYear = taxYears => {
        if (!taxYears || !taxYears[0]) {
            return ;
        }
        // Default: first tax year
        let taxYear = taxYears[0].year;
        for (let { variations, year } of taxYears) {
            const found = variations.find(({ highlighted, status }) => {
                if (highlighted !== undefined) {
                    return highlighted;
                } else {
                    return status === productStatuses.HIGHLIGHTED;
                }
            });
            if (found) {
                taxYear = year;
                break;
            }
        }
        return taxYear;
    };

    handleMenuClick = activeMenuItem => {
        return () => this.setState({ activeMenuItem });
    };

    renderHeader = () => {
        const { name, semanticIcon } = this.props.product;

        return (
            <PageHeader
                content={name}
                icon={semanticIcon}
            />
        );
    };

    renderMenu = () => {
        return (
            <Menu fluid pointing secondary vertical>
                {this.renderTaxYearMenuItems()}                
            </Menu>
        );
    };

    renderTaxYearMenuItems = () => {
        const { taxYears, taxYearsToShow } = this.state;
        const menuItems = [];

        if (isErhverv(this.props.userData) && !isImpersonating()) {
            menuItems.push(
                <Menu.Item
                    content='Opstartshjælp'
                    icon='rocket'
                    onClick={this.handleMenuClick(STARTER_HELP_ID)}
                    active={this.state.activeMenuItem === STARTER_HELP_ID}
                />
            );
        }

        if(JOINT_TAX() && isSelskab(this.props.userData)){
                menuItems.push(
                    <Menu.Item
                        content='Ejerskabsforhold'
                        icon='group'
                        onClick={this.handleMenuClick('ownerShip')}
                        active={this.state.activeMenuItem === 'ownerShip'}
                    />
                );
        }

        for (let i = 0; i < taxYearsToShow; i++) {
            const taxYear = taxYears[i];
            menuItems.push(
                <Menu.Item
                    className={styles.taxYearGrouping}
                    name={`${i18n.taxYear} ${taxYear.year}`}
                    content={`${i18n.taxYear} ${taxYear.year}`}
                    key={taxYear.year}
                    active={this.state.activeMenuItem === taxYear.year}
                    onClick={this.handleMenuClick(taxYear.year)}
                />,
            );
        }

        if (taxYearsToShow < taxYears.length) {
            menuItems.push(
                <Menu.Item
                    link
                    content={<ColoredText
                        color='grey'
                        content='Vis tidligere skatteår...'
                    />}
                    onClick={() => {
                        this.setState({
                            taxYearsToShow: Math.min(taxYearsToShow + 2, taxYears.length),
                        });
                    }}
                />,
            );
        }



        return menuItems;
    };

    renderTaxYear = taxYear => {
        return <TaxYearNew year={taxYear} {...this.props} />;
    };

    renderMenuContent = () => {
        const { activeMenuItem, taxYears } = this.state;

        if (activeMenuItem === STARTER_HELP_ID) {
            return <StarterHelp />;
        }
        
        if (activeMenuItem === 'ownerShip') {
            return <RenderOwnerShipTree userData={this.props.userData}/>;
        }

        const taxYear = taxYears.find(ty => ty.year === activeMenuItem);
        return taxYear && this.renderTaxYear(taxYear)
    };

    renderMissingRole = () => {
        return (
            <Message
                icon='ban'
                header={i18n.wrongUserTypeError.header}
                content={
                    <span>
                        <p>{i18n.wrongUserTypeError.body}</p>
                        <Button 
                            content={i18n.wrongUserTypeError.goBackButton}
                            onClick={() => this.props.history.push('/')}
                        />
                    </span>
                }
            />
        );
    };

    renderNoRelevantTaxYears = () => {
        return <Message
            header={i18n.noRelevantTaxYearsError.header}
            content={compileMarkdown(i18n.noRelevantTaxYearsError.body)}
        />;
    };

    renderContent = () => {
        const { loading } = this.props;
        if (loading) {
            return <Loader inline='centered' active />;
        }

        if (this.state.missingRequiredRole) {
            return this.renderMissingRole();
        }

        if (this.state.taxYears.length === 0) {
            return this.renderNoRelevantTaxYears();
        }

        return (
            <Grid stackable>
                <Grid.Column width={4}>
                    {this.renderMenu()}
                </Grid.Column>
                <Grid.Column width={12} >
                    {this.renderMenuContent()}
                </Grid.Column>
            </Grid>
        );
    };

    render () {
        return (
            <ProductPage key={this.props.product.id} subHeader={this.renderHeader()}>
                {this.renderContent()}
            </ProductPage>
        );
    }
}

export default compose(
    withUserData,
    withRouter,
)(AppLauncher);