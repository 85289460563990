import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Message, Button, Header } from 'semantic-ui-react';

import queryParams from 'util/QueryParams';
import AuthHelper from 'util/AuthHelper';
import i18n from 'i18n/pages/Login';
import ColoredText from 'design/atoms/ColoredText';
import HeroForm from 'design/atoms/HeroForm';
import { CreditsContext } from 'design/molecules/CreditsProvider';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailVal: '',
            pwVal: '',
            isFetching: false,
            errors: {}, // may contains properties: "other", "email", and "password"
        };
    }

    componentDidMount = () => {
        const { email } = queryParams();
        if (email) {
            this.setState({ emailVal: email });
        }
    };

    getRedirectionTarget = () => {
        const { invitationToken, redirect } = queryParams();

        if (invitationToken) {
            // redirect to invitation acceptance page
            return `/invitation/${invitationToken}`;
        }

        // default: use "redirect" query param
        return redirect;
    };

    handleChange = (_, { name, value }) => this.setState({ [name]: value });

    handleLogin = (e) => {
        const { emailVal, pwVal } = this.state;
        if (e) e.preventDefault();
        this.setState({ isFetching: true });
        AuthHelper.authenticate(
            {
                email: emailVal,
                password: pwVal,
            },
            this.props.history,
            true,
            this.getRedirectionTarget(),
            () => this.context.refetch(),
        ).catch((errors) => {
            this.setState({ errors, isFetching: false });
        });
    };

    resetErrorMsgs = () => {
        this.setState({ errors: {} });
    };

    renderForm = () => {
        const { search } = this.props.location;
        const { other = '', email = '', password = '' } = this.state.errors;
        const hasAnyError = !!other || !!email || !!password;
        let errorList = [other, email, password].filter((e) => e !== '');

        return (
            <Form error={hasAnyError}>
                <Form.Field>
                    <Header size='large'>
                        Velkommen tilbage
                        <span role='img' aria-label=':wave:'>
                            👋
                        </span>
                    </Header>
                    <div style={{ marginBottom: '2em' }}>
                        Velkommen! Indtast dine loginoplysninger nedenfor.
                    </div>
                </Form.Field>

                <Form.Input
                    autoCorrect='off'
                    autoCapitalize='off'
                    type='email'
                    id='form-input-email'
                    placeholder={i18n.emailPlaceholder}
                    label={i18n.emailLabel}
                    value={this.state.emailVal}
                    name='emailVal'
                    onChange={this.handleChange}
                    error={!!email}
                />

                <Form.Input
                    label={i18n.passwordLabel}
                    autoCorrect='off'
                    autoCapitalize='off'
                    type='password'
                    id='form-input-password'
                    placeholder={i18n.passwordPlaceholder}
                    value={this.state.pwVal}
                    name='pwVal'
                    onChange={this.handleChange}
                    error={!!password}
                />

                {/* general error display. shown if any error */}
                <Message error header={i18n.errorHeader} list={errorList} />

                <Form.Field>
                    <ColoredText
                        link='/nulstil'
                        content={i18n.forgotten}
                        color='green'
                    />
                </Form.Field>

                <Form.Field>
                    <Button
                        primary
                        fluid
                        onClick={this.handleLogin}
                        disabled={this.state.isFetching}
                        loading={this.state.isFetching}
                        content={i18n.loginButton}
                        style={{ marginTop: '2em' }}
                    />
                </Form.Field>

                <Form.Field style={{ textAlign: 'center' }}>
                    Har du ikke en bruger?&nbsp;
                    <ColoredText
                        color='green'
                        content={i18n.linkToRegister}
                        link={`/register${search}`}
                    />
                </Form.Field>
            </Form>
        );
    };

    render = () => {
        return (
            <HeroForm>{this.renderForm()}</HeroForm>
        );
    };
}

Login.contextType = CreditsContext;

export default withRouter(Login);
