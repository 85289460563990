import React, { useCallback } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import usePaymentState from '../shared/usePaymentState';
import PaymentShell from '../shared/PaymentShell';
import { getProductByID } from 'util/getProducts';
import useRestResource from 'util/useRestResource';
import PaymentPanels from '../shared/PaymentPanels';
import PaymentOverview from '../shared/PaymentOverview';
import useDeferredRedirect from 'util/useDeferredRedirect';
import { getParam } from 'util/QueryParams';
import HighFive from '../shared/HighFive';
import { getAddonProductByID, getSubscriptionsOverview, updateSubscriptionPackageTier } from 'http/payment';
import { ShoppingCartSegment } from '../shared/ShoppingCart';
import getSubscriptionPackageUpgradeInfo, { SubscriptionPackageUpdateDescription } from '../shared/getSubscriptionPackageUpgradeInfo';

const prepareUpgradeInfo = async (productID, tierIDToUpgradeTo) => {
    const [product, currentSubscriptions] = await Promise.all([
        getProductByID(productID),
        getSubscriptionsOverview(),
    ]);

    const tier = product.subscriptionPackageSet.tiers.find(tier => tier.id === tierIDToUpgradeTo);
    const addonProduct = await getAddonProductByID(tier.addonProductID);

    const subscription = currentSubscriptions.find(sub => sub.productID === productID);
    let subscriptionUpdateInfo;
    if (subscription) {
        subscriptionUpdateInfo = getSubscriptionPackageUpgradeInfo(
            product,
            subscription.selectedPackageTier,
            tierIDToUpgradeTo,
        );
    }

    return {
        product,
        subscriptionUpdateInfo,
        addonProduct,
    };
};

const PaymentFlowUpgradePackage = () => {
    const { productID, taxYear, packageTier } = useParams();

    const upgradeInfoFetcher = useCallback(() => prepareUpgradeInfo(productID, packageTier), [productID, packageTier]);

    const { loading, error, data } = useRestResource({
        fetcher: upgradeInfoFetcher,
    });

    const { product, subscriptionUpdateInfo, addonProduct } = data || {};

    
    const tiers = product?.subscriptionPackageSet?.tiers || [];
    const desiredTier = tiers.find(tier => tier.id === packageTier);
    
    const orderLines = [];
    if (product) {
        orderLines.push({
            icon: desiredTier.semanticIcon,
            description: `${addonProduct.name} ${taxYear}`,
            price: desiredTier.price - product.defaultPrice,
            metadata: {
                addonProductPurchase: {
                    productID: desiredTier.addonProductID,
                    data: {
                        taxYear: taxYear.toString(),
                    },
                },
            },
        });
    }
    
    const paymentState = usePaymentState({
        orderLines,
    });

    useDeferredRedirect(paymentState.paymentDone, getParam('redirect', '/'));

    const handlePaymentRequest = async () => {
        const { success } = await paymentState.performPaymentRequest();
        if (!success) {
            return;
        }

        if (subscriptionUpdateInfo) {
            await updateSubscriptionPackageTier(product.id, subscriptionUpdateInfo.nextTier.id);
        }
    };

    const renderContent = () => {
        if (loading) {
            return <Loader inline='centered' active />;
        }

        if (error) {
            return <Message error content='Der opstod en fejl' />;
        }

        if (paymentState.paymentDone) {
            return <HighFive />;
        }
        
        return (
            <PaymentPanels
                leftPanel={
                    <>
                        {paymentState.renderPaymentMethod()}
                        <ShoppingCartSegment icon={desiredTier.semanticIcon} title={`${addonProduct.name} ${taxYear}`}>
                            {addonProduct.description}
                        </ShoppingCartSegment>
                        {subscriptionUpdateInfo && (
                            <SubscriptionPackageUpdateDescription
                                fromTier={subscriptionUpdateInfo.prevTier.name}
                                toTier={subscriptionUpdateInfo.nextTier.name}
                            />
                        )}
                    </>
                }
                rightPanel={
                    <PaymentOverview
                        proactiveVat={product.vat}
                        orderLines={orderLines}
                        extraContent={(
                            <div>
                                {paymentState.renderPayButton(handlePaymentRequest)}
                                {paymentState.renderError()}
                            </div>
                        )}
                    />
                }
            />
        );
    };

    return (
        <PaymentShell
            title='Opgrader pakke'
            paymentDone={paymentState.paymentDone}
            disabled={paymentState.working}
            children={renderContent()}
        />
    );
};

export default PaymentFlowUpgradePackage;