import React from 'react'
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Checkbox, Form, Icon, Image, Label, Message, Segment, Dropdown } from 'semantic-ui-react';
import { ERHVERV } from 'util/userMethods';
import erpSystems, { erpIdentifiers } from 'util/erpSystems';
import queryParams from 'util/QueryParams';
import i18n from 'i18n/pages/Register';
import * as dynaccount from 'http/dynaccount'
import * as economic from 'http/economic';
import * as dinero from 'http/dinero';
import CVRSearch from 'design/molecules/CVRSearch';
import { Media } from 'design/atoms/Media';

const PROP_CVR = 'cvr';

const OutgoingLink = ({ content, to }) => {
	return (
		<a
			children={<u>{content}</u>}
			href={to}
			target='_blank'
			rel='noopener noreferrer'
		/>
	);
};

class RegisterForm extends React.Component {
	constructor (props) {
		super(props);
		this.initialValues = {};
		this.state = {
			loading: true,
			integrationError: false,
			erpIntegration: '',
			organisations: [],
		};
	}

	static propTypes = {
		userSegment: PropTypes.string.isRequired,
		redirecting: PropTypes.bool,
		errors: PropTypes.object.isRequired,
	};

	static defaultProps = {
		redirection: false,
	};

	componentDidMount = async () => {
		await this.preparePrefill();
		this.setState({ loading: false });
	};

	preparePrefill = async () => {
		const { token, referrer, email } = queryParams();

		if (email) {
			this.fillOutValues({ email });
		}

		if (token && referrer) {
			// ERP integrations
			try {
				await this.handleTokenPrefill(token, referrer);
			} catch {
				this.setState({ integrationError: true });
			}
		}
	};

	handleDynaccountIntegration = async token => {
		const { email, phone, cvr, redirect } = await dynaccount.extractPrefillValues(token);

		if (redirect) {
			return this.props.history.push(`${redirect}?email=${email}`);
		}

		this.fillOutValues({ email, phone, cvr, erpCredentials: token });
	};

	handleEconomicIntegration = async token => {
		const { email, phone, cvr } = await economic.extractPrefillValues(token);
		this.fillOutValues({ email, phone, cvr, erpCredentials: token });
	};

	handleDineroIntegration = async token => {
		const allOrganisations = await dinero.getOrganisationsPrefill(token);
		const organisations = allOrganisations.filter(({ vatNumber }) => vatNumber);

		if (organisations.length === 0) {
			return this.setState({ integrationError: true });
		}

		if (organisations.length === 1) {
			const [firstOrganisation] = organisations;
			this.setSelectedDineroOrganisation(firstOrganisation);
		}

		this.setState({ organisations });
	};

	handleTokenPrefill = async (token, referrer) => {
		if (this.props.userSegment !== ERHVERV) {
			return;
		}

		const prefillHandler = {
			[erpIdentifiers.dynaccount]: this.handleDynaccountIntegration,
			[erpIdentifiers.economic]: this.handleEconomicIntegration,
			[erpIdentifiers.dinero]: this.handleDineroIntegration,
		}[referrer];

		if (!prefillHandler) {
			return;
		}

		await prefillHandler(token);

		this.props.setIsPerformingIntegration(true);
		this.fillOutValues({ referrer });
		this.setState({
			erpIntegration: referrer,
		});
	};

	fillOutValues = (prefill = {}) => {
		const { values } = this.props;
		for (const [name, value] of Object.entries(prefill)) {
			if (!value) continue;
			if (values[name]) continue;

			this.initialValues[name] = value;
			this.props.onFieldChange(null, { name, value });
		}
		this.props.validateFormValues();
	};

	setSelectedDineroOrganisation = ({ email, phone, vatNumber, id }) => {
		const { token } = queryParams();

		this.fillOutValues({
			email,
			phone,
			cvr: vatNumber,
			erpCredentials: {
				token,
				organisationID: id,
			},
		});
	};

	getRedirectionNotice = () => {
		if (!this.props.redirecting) {
			return null;
		}

		return (
			<div className='info'>
				<span>{i18n.RedirectNotice.message} </span>
				<Link className='otherOption' to={`/login${window.location.search}`}>
					{i18n.RedirectNotice.link}
				</Link>
			</div>
		);
	};

	renderFormInput = ({ name, label, placeholder, ...other }) => {
		const { errors, values, correct, onFieldChange, validateFormValues, relevantProperties } = this.props;

		if (!relevantProperties.has(name)) {
			return null;
		}

		const icon = correct.has(name) && (
			<Icon name='check' color='green' />
		);

		return (
			<Form.Input
				name={name}
				label={label}
				placeholder={placeholder}
				icon={icon}
				onChange={onFieldChange}
				onBlur={validateFormValues}
				defaultValue={values[name] || this.initialValues[name]}
				error={errors[name]}
				fluid
				{...other}
			/>
		);
	};

	renderPasswordFields = () => {
		return (
			<Form.Group widths='equal'>
				{this.renderFormInput({
					name: 'password',
					placeholder: i18n.RegisterForm.passwordPlaceholder,
					label: i18n.RegisterForm.passwordLabel,
					type: 'password',
				})}
				{this.renderFormInput({
					name: 'repeatPassword',
					placeholder: i18n.RegisterForm.repeatPasswordPlaceholder,
					label: i18n.RegisterForm.repeatPasswordLabel,
					type: 'password',
				})}
			</Form.Group>
		);
	}

	renderConfirmCheckboxes = () => {
		const { onFieldChange, validateFormValues, errors, values, relevantProperties } = this.props;

		const renderCheckboxField = ({ id, label }) => {
			if (!relevantProperties.has(id)) {
				return null;
			}

			const renderCheckbox = (isMobile) => (
				<Checkbox
					id={id}
					name={id}
					toggle={isMobile}
					onChange={onFieldChange}
					onBlur={validateFormValues}
					defaultChecked={values[id]}
				/>
			);

			const error = errors[id];
			
			const errorLabelJSX = (
				error &&
				<div>
					<Label pointing='above' color='red' content={error} basic />
				</div>
			);

			return (
				<Form.Field error={!!error} style={{display:'flex'}}>
					<Media gte='tablet'>
						{renderCheckbox()}
						{<div style={{ display: 'inline-block', paddingLeft: '4px' }}>{label}</div>}
						{errorLabelJSX}
					</Media>
					<Media lt='tablet'>
						{<div>{label}</div>}
						{renderCheckbox(true)}
						{errorLabelJSX}
					</Media>
				</Form.Field>
			);
		};

		return (
			<Form.Group grouped >
                {/* newsletter */}
                {renderCheckboxField({
                    id: 'wantsNews',
                    label: i18n.RegisterForm.newsLetterLabel,
                })}

                {/* terms of agreement */}
                {renderCheckboxField({
                    id: 'consent',
                    label: (
						<>
                        <span>
                            {i18n.RegisterForm.termsLabel1}
                            <OutgoingLink
                                content={`${i18n.RegisterForm.termsLabel2}`}
                                to='https://digitalrevisor.nu/vilkaar-og-forretningsbetingelser/'
                            />
							{" "}
							og
							{" "}
							<OutgoingLink
                                content={`${i18n.RegisterForm.consentLabel2}`}
                                to='https://digitalrevisor.nu/samtykke/'
                            />
                        </span>

						</>
                    ),
                })}
			</Form.Group>
		);
	}

	renderErpReferrer = () => {
		const { erpIntegration } = this.state;

		const erpSystem = erpSystems[erpIntegration];
		if (!erpSystem) {
			return;
		}

		return <Segment textAlign='center' basic>
			<div style={{ marginBottom: '1em' }}>Din konto integreres med:</div>
			<Image
				src={erpSystem.logo}
				alt={erpSystem.name}
				title={erpSystem.name}
				size='medium'
				centered
			/>
		</Segment>;
	};

	renderIntegrationError = () => {
		return <Message
			warning
			icon='warning circle'
			header='Fejl i forbindelse med integration'
			content='Der opstod en fejl i forbindelse med integrationen'
		/>;
	};

	renderOrganisationSelector = () => {
		const { organisations } = this.state;

		let defaultValue;
		let disabled;

		if (organisations.length === 1) {
			// auto-select the only company that is available to the user
			const [{ id }] = organisations;
			defaultValue = id;
			disabled = true;
		}

		return (
			<Form.Field>
				<label>Vælg firma</label>
				<Dropdown
					selection
					search
					fluid
					placeholder='Vælg firma'
					defaultValue={defaultValue}
					disabled={disabled}
					onChange={(_, { value }) => {
						const selectedOrganisation = organisations.find(({ id }) => id === value);
						this.setSelectedDineroOrganisation(selectedOrganisation);
					}}
					options={organisations.map(({ name, id }) => {
						return {
							text: name,
							value: id,
							key: id,
						};
					})}
				/>
			</Form.Field>
		);
	};

	renderPrivatFormFields = () => {
		const firstnameJSX = this.renderFormInput({
			name: 'firstname',
			placeholder: i18n.RegisterForm.firstnamePlaceholder,
			label: i18n.RegisterForm.nameLabel,
		});

		const lastnameJSX = this.renderFormInput({
			name: 'lastname',
			placeholder: i18n.RegisterForm.lastnamePlaceholder,
			label: i18n.RegisterForm.lastnameLabel,
		});

		if (!firstnameJSX && !lastnameJSX) {
			return null;
		}

		return (
			<Form.Group widths='equal'>
				{firstnameJSX}
				{lastnameJSX}
			</Form.Group>
		);
	};

	renderCompanyPicker = () => {
		const { errors, onFieldChange, validateFormValues, relevantProperties } = this.props;

		if (!relevantProperties.has(PROP_CVR)) {
			return null;
		}

		const { organisations } = this.state;
		if (organisations.length > 0) {
			return this.renderOrganisationSelector();
		}

		return (
			<CVRSearch
				cvr={this.initialValues[PROP_CVR]}
				onBlur={validateFormValues}
				error={errors[PROP_CVR]}
				setWarning={this.setWarning}
				onChange={({ cvr }) => onFieldChange(null, { name: PROP_CVR, value: cvr })}
				showDropdown={false}
			/>
		);
	};

	renderAcademyFormFields = () => {
		return <>
			{this.renderFormInput({
				name: 'className',
				placeholder: i18n.RegisterForm.classNamePlaceholder,
				label: i18n.RegisterForm.classNameLabel,
			})}
		</>;
	};

	renderAccountFormFields = () => {
		return <>
			{this.renderFormInput({
				name: 'email',
				placeholder: i18n.RegisterForm.emailPlaceholder,
				label: i18n.RegisterForm.emailLabel,
			})}
			{this.renderFormInput({
				name: 'phone',
				placeholder: i18n.RegisterForm.phonePlaceholder,
				label: i18n.RegisterForm.phoneLabel,
			})}
			{this.renderPasswordFields()}
			{this.renderConfirmCheckboxes()}
		</>;
	};

	renderRegisterForm = () => {
		return (
			<Form>
				{this.renderErpReferrer()}
				{this.renderPrivatFormFields()}
				{this.renderCompanyPicker()}
				{this.renderAcademyFormFields()}
				{this.renderAccountFormFields()}
			</Form>
		);
	};

	render = () => {
		const { loading, integrationError } = this.state;

		if (loading) {
			return <Segment loading basic />;
		}

		// show error if an integration was attempted, but failed
		if (integrationError) {
			return this.renderIntegrationError();
		}

		return this.renderRegisterForm();
	};
}

export default withRouter(RegisterForm);
