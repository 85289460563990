import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useState } from 'react';
import { Divider } from 'semantic-ui-react';

import HintWrapper from './hint_wrapper/HintWrapper';
import HintWrapperModel from './hint_wrapper/HintWrapperModel';
import AlternativeContent from './util/AlternativeContent';

import HintWrapperSources from './hint_wrapper/HintWrapperSourceTypes';
import { HintTypes } from './hint/HintTypes';
import style from './HintSection.module.scss';
import { ProductElementInteractionDataTypes } from 'design/pages/Product/field_interactivity/ProductElementInteractionData';
import ProductSections from './util/ProductSections';
import HintSectionAnalyser from './util/HintSectionAnalyser';

const HintSection = forwardRef((props, ref) => {
    const {
        productMetadata,
        productData,
        sectionFieldWrappers,
        currentSectionSlug,
        factsBeingUpdated,
        hasPaid,
        productPageStyle,
        children,
        timer
    } = props;

    // ==================================================

    const [sectionMetadataHintWrappers, setSectionMetadataHintWrappers] = useState([]);
    const [fieldVideoHintWrapper, setFieldVideoHintWrapper] = useState();
    const [fieldBasicHintWrapper, setFieldBasicHintWrapper] = useState();
    
    // Custom hint props passed to hints with different types
    const [customHintProps, setCustomHintProps] = useState({});
    const updateCustomHintProps = (fn) => {
        const customHintPropsCopy = {...customHintProps};
        const updatedCustomHintProps = fn(customHintPropsCopy);
        setCustomHintProps(updatedCustomHintProps);
    };

    const [hintsContainerMarginTop, setHintsContainerMarginTop] = useState(0);
    
    const HintSectionPlacement = {
        LEFT: 'LEFT',
        RIGHT: 'RIGHT'
    };

    // ==================================================

    const SECONDS_TO_TRIGGER_HINT = 1;
    const [recentlyTriggeredHint, setRecentlyTriggeredHint] = useState(null);
    const [hintInFocus, setHintInFocus] = useState(false);

    // --------------------------------------------------

    useEffect(() => {
        onSectionSelected();
    }, [currentSectionSlug]);

    useLayoutEffect(() => {
        /**
         * Since product page header has position absolute and is drawn on top of everything else, we cannot position the hints container at `top: 0`.
         * There must be some margin from the top that we have to calculate.
         */
        const productPageHeader = document.getElementById('product-page__header');

        let headerHeight = 0;
        if (productPageHeader) {
            headerHeight = productPageHeader.getClientRects()[0].height;
        }

        setHintsContainerMarginTop(`calc(${headerHeight}px + ${productPageStyle.paddingTop})`);
    }, [productPageStyle.paddingTop]);

    // --------------------------------------------------
    
    const hintSectionAnalysis = useMemo(() => {
        const activeSection = productMetadata.sections.find(section => section.slug === currentSectionSlug);
        return HintSectionAnalyser.analyseHintSection(activeSection, sectionFieldWrappers);
    }, [productMetadata, currentSectionSlug, sectionFieldWrappers]);

    const onSectionSelected = () => {
        const sectionHintWrappers = _getSectionHintWrappers();
        setSectionMetadataHintWrappers(sectionHintWrappers);

        Object.values(HintTypes).forEach(hintType => {
            const skip = ![HintTypes.BASIC, HintTypes.VIDEO].includes(hintType)
            if(skip) {
                return;
            }

            const presentInHintSection = hintSectionAnalysis?.containsFieldHintsOfType(hintType);
            const hintWrapper = presentInHintSection ? new HintWrapperModel(null, HintWrapperSources.FIELD) : null;
            
            let fn = () => {};
            if(hintType === HintTypes.BASIC) {
                fn = setFieldBasicHintWrapper;
            } else if(hintType === HintTypes.VIDEO) {
                fn = setFieldVideoHintWrapper;
            }
            fn(hintWrapper);
        });

        // ==================================================

        // reset custom props for hint type VideosErp
        updateCustomHintProps(customHintPropsCopy => {
            customHintPropsCopy[HintTypes.VIDEOS_ERP] = null;
            return customHintPropsCopy;
        });
    };

    // --------------------------------------------------

    const onProductElementInteraction = productElementInteraction => {
        const { interactionData, event } = productElementInteraction;
        const { type: interactionType, interactionContext, customData } = interactionData;
        const {
            FIELD_WRAPPER,
            FOLDABLE_SEGMENT_PANEL
        } = ProductElementInteractionDataTypes;

        // ==================================================

        switch(interactionType) {
            case FIELD_WRAPPER: {
                // Assume the interactionData.customData is a product field
                const field = customData;

                // =========================

                if(field.type === 'group') {
                    return;
                }

                if(!field.isAssociatedWithHint || !field.hint) {
                    return;
                }
                
                if(event.type === "focus") {
                    showHint(field, HintWrapperSources.FIELD, event);
                    setHintInFocus(true);

                    timer.reset();
                    timer.stop();
                    timer.removeAllEventListeners();
                } else if(event.type === "blur") {
                    hideHint(field.hint);

                    setHintInFocus(false);
                } else if(event.type === "mouseenter") {
                    if(hintInFocus) {
                        // Do not react to mouseenter event when some hint is in focus
                        return;
                    }

                    timer.start();
                    timer.addEventListener('secondsUpdated', function(event) {
                        if(recentlyTriggeredHint) {
                            return;
                        }

                        const timer = event.detail.timer;

                        const { seconds } = timer.getTimeValues();
                        if(seconds >= SECONDS_TO_TRIGGER_HINT) {
                            const hintShown = showHint(field, HintWrapperSources.FIELD, event);
                            if(!hintShown) {
                                return;
                            }
                            timer.reset();
                            timer.stop();
                            timer.removeAllEventListeners();
                        };
                    });
                } else if(event.type === 'mouseleave') {
                    timer.reset();
                    timer.stop();
                    timer.removeAllEventListeners();

                    if(hintInFocus) {
                        return;
                    }

                    hideHint(field.hint);
                }
                break;
            } case FOLDABLE_SEGMENT_PANEL: {
                if(interactionContext.section === ProductSections.ERP_CONNECTION) {
                    if(event.type === 'click') {
                        const { isOpen } = customData;
                        if(isOpen) {
                            updateCustomHintProps(customHintPropsCopy => {
                                customHintPropsCopy[HintTypes.VIDEOS_ERP] = null;
                                return customHintPropsCopy;
                            });
                            return;
                        }

                        const hintWrapper_VideosErp = _getSectionHintWrappers().find(sectionHint => sectionHint.hint.type === HintTypes.VIDEOS_ERP);
                        if(!hintWrapper_VideosErp) {
                            return;
                        }
                        
                        updateCustomHintProps(customHintPropsCopy => {
                            customHintPropsCopy[HintTypes.VIDEOS_ERP] = {
                                selectedErp: interactionContext.erpSystemID
                            };
                            return customHintPropsCopy;
                        });
                    }
                }
                break;
            }
        }
    };

    useImperativeHandle(ref, () => ({
        onProductElementInteraction
    }));

    // --------------------------------------------------

    const _getSectionHintWrappers = () => {
        const { sections } = productMetadata;
        const activeSection = sections.find(section => section.slug === currentSectionSlug);
        const {
            isAssociatedWithHint,
            hint
        } = activeSection;

        // --------------------------------------------------
        
        const sectionHintWrappers = [];
        if(isAssociatedWithHint) {
            const hintWrapper = new HintWrapperModel(hint, HintWrapperSources.SECTION);
            sectionHintWrappers.push(hintWrapper);
        }
       
        return sectionHintWrappers;
    };

    // --------------------------------------------------

    const isEmpty = () => {
        if(!hintSectionAnalysis) {
            return true;
        }
        const sectionContainsHints = hintSectionAnalysis.containsSectionHints();
        const fieldsContainHints = hintSectionAnalysis.containsFieldHints();
        return !sectionContainsHints && !fieldsContainHints;
    };

    // -------------------------

    const aggregateSectionHints = () => {
        return [...sectionMetadataHintWrappers];
    };

    const aggregateFieldHints = () => {
        const result = [];
        if(fieldVideoHintWrapper) {
            result.push(fieldVideoHintWrapper);
        }
        if(fieldBasicHintWrapper) {
            result.push(fieldBasicHintWrapper);
        }

        return result;
    };

    // --------------------------------------------------

    const showHint = (field, hintWrapperSource, triggerEvent) => {
        const fieldHintWrapper = new HintWrapperModel(field.hint, hintWrapperSource);
        if(field.hint.type === HintTypes.BASIC) {
            setFieldBasicHintWrapper(fieldHintWrapper);
        } else if(field.hint.type === HintTypes.VIDEO) {
            setFieldVideoHintWrapper(fieldHintWrapper);
        }

        if(!recentlyTriggeredHint || !hintInFocus) {
            setRecentlyTriggeredHint({
                hint: field.hint,
                triggerEvent
            });
        }
        return true;
    };

    const hideHint = hint => {
        // Hide the hint if it's not a video
        if(fieldBasicHintWrapper.hint && hint.type === HintTypes.BASIC) {
            setFieldBasicHintWrapper(new HintWrapperModel(null, HintWrapperSources.FIELD));
        }

        setRecentlyTriggeredHint(null);
    };

    // --------------------------------------------------

    const renderAlternativeContent = (text, hidden = false) => {
        const placeholder = (
            <div className={style['empty-hint-container-content']}>
                <p>{text}</p>
            </div>
        );

        const alternativeContent = new AlternativeContent(placeholder, hidden);
        return alternativeContent;
    };

    const renderHintWrapper = hintWrapper => {
        let altContent = null;
        const showFieldEmptyHintContainer = hintWrapper.source === HintWrapperSources.FIELD && !hintWrapper.hint;
        if(showFieldEmptyHintContainer) {
            if(hintWrapper === fieldBasicHintWrapper) {
                altContent = renderAlternativeContent('', true);
            } else if(hintWrapper === fieldVideoHintWrapper) {
                altContent = renderAlternativeContent('', true);
            }
        }

        // ==================================================

        return <HintWrapper
            sectionFieldWrappers={sectionFieldWrappers}
            productData={productData}
            productMetadata={productMetadata}
            hasPaid={hasPaid}
            factsBeingUpdated={factsBeingUpdated}
            hintWrapper={hintWrapper}
            altContent={altContent}
            customHintProps={customHintProps[hintWrapper.hint?.type] || {}}
        />;
    };

    const renderHints = () => {
        const sectionHints = aggregateSectionHints().map(x => renderHintWrapper(x));

        // --------------------------------------------------

        const fieldHintWrappers = aggregateFieldHints()
        const fieldHints = fieldHintWrappers.map(x => renderHintWrapper(x));

        const renderDivider = sectionHints.length > 0 && fieldHintWrappers.some(fieldHintWrapper => fieldHintWrapper.source === HintWrapperSources.FIELD && fieldHintWrapper.hint);

        return (
            <>
                {sectionHints}
                {renderDivider && <Divider />}
                {fieldHints}
            </>
        );
    };

    // Do not render hint section if we have nothing to show.
    if(isEmpty()) {
        return children;
    }

    // --------------------------------------------------

    const activeSection = productMetadata.sections.find(section => section.slug === currentSectionSlug);
    const hintSectionPosition = !activeSection.showHintSectionToRight ? HintSectionPlacement.LEFT : HintSectionPlacement.RIGHT;

    const hintsContainerStyle = {
        top: hintsContainerMarginTop,
        [`padding-${hintSectionPosition === HintSectionPlacement.LEFT ? 'right' : 'left'}`]: '1em'
    };

    const hintContainer = (
        <div className={style['hints-container']} style={hintsContainerStyle}>
            {renderHints()}
        </div>
    );
    const childrenContainer = (
        <div className={style['children-container']}>
            {children}
        </div>
    );

    // =========================

    const hintSectionElements = [hintContainer, childrenContainer];
    if(hintSectionPosition !== HintSectionPlacement.LEFT) {
        hintSectionElements.reverse();
    }

    return (
        <div className={style['hint-section']}>
            {hintSectionElements}
        </div>
    );
});

export default HintSection;
