import React from 'react';
import { Button, Header, Icon, Message } from 'semantic-ui-react';

const PackageFeatureItem = ({ content, isLocked = true }) => {
    return (
        <div
            style={{
                display: 'flex',
                paddingLeft: '1em',
            }}
        >
            <Icon
                name={isLocked ? 'x' : 'check'}
                color={isLocked ? 'red' : 'green'}
            />
            &nbsp;&nbsp;&nbsp;
            <span>{content}</span>
        </div>
    );
};

const Package = ({
    title,
    icon,
    description,
    price,
    bgImage,
    onChoose,
    features,
    lockedFeatures,
    isSelected,
    isChangingPackage = false,
    isUpgrade = false,
}) => {
    const renderButton = () => {
        let buttonContent;
        let buttonColor;
        if (isChangingPackage) {
            buttonContent = isSelected ? <span><Icon name='check' /> Valgt</span> : <span>Skift</span>;
            buttonColor = isSelected ? 'grey' : (
                isUpgrade ? 'green' : 'black'
            );
        } else {
            buttonContent = <span>{price},-</span>;
            buttonColor = 'green';
        }

        return (
            <Button
                circular
                size='big'
                color={buttonColor}
                style={{ width: '175px', pointerEvents: isSelected && 'none' }}
            >
                {buttonContent}
            </Button>
        );
    };

    return (
        <div
            onClick={onChoose}
            style={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '1em',
                width: '400px',
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px',
            }}
        >
            <div
                style={{
                    width: '100%',
                    background: '#46C476',
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: 'cover',
                    color: 'white',
                    borderTopRightRadius: '1em',
                    borderTopLeftRadius: '1em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100px',
                }}
            >
                <div
                    style={{
                        fontSize: icon ? '180%' : '240%',
                        fontWeight: 'bold',
                        zIndex: 1,
                        marginTop: '0.25em',
                    }}
                >
                    <Header as='h2' style={{ color: 'black' }}>
                        <Icon name={icon} style={{ }} />
                        <Header.Content>
                            {title.header}
                            {title.subheader && (
                                <Header.Subheader>
                                    <span style={{ fontWeight: '900', color: 'white', fontSize: '1.3em' }}>
                                        {title.subheader}
                                    </span>
                                </Header.Subheader>
                            )}
                        </Header.Content>
                    </Header>
                </div>
            </div>
            <div style={{ position: 'relative', textAlign: 'center', background: 'white', borderBottomLeftRadius: '1em', borderBottomRightRadius: '1em' }}>
                <div
                    style={{
                        padding: '2em',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5em',
                        marginBottom: '2em',
                    }}
                >
                    <div style={{ marginBottom: '0.5em' }}>
                        <div
                            style={{
                                opacity: 0.75,
                                fontWeight: 'bold',
                            }}
                        >
                            {description}
                        </div>
                    </div>
                    {features.map(statement => {
                        return (
                            <PackageFeatureItem
                                content={statement}
                                isLocked={lockedFeatures.includes(statement)}
                            />
                        );
                    })}
                    {(isChangingPackage) && !(isNaN(price)) && (
                        <div style={{ marginTop: '1em' }}>
                            <Message compact style={{ borderRadius: '1em' }}>
                                <b><Icon name='tag' /> {price},-  årligt</b>
                            </Message>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        bottom: '-25.5px',
                    }}
                >
                    {renderButton()}
                </div>
            </div>
        </div>
    );
};

export default Package;