import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { IS_PRODUCTION } from 'config/config';
import { AuthRoute, NoAuthRoute } from 'design/atoms/AuthRoute';
import * as systemUpdate from 'util/systemUpdate';
import handleComponentError from 'util/handleComponentError';
import googleTagManager from 'util/GoogleTagManager';
import CreditsProvider from 'design/molecules/CreditsProvider';
import RegularTawkTo from 'design/molecules/TawkTo/regular/RegularTawkTo';
import onStartup from './startup';

// load always (shared/small pages)
import Login                      from './design/pages/Login';
import Register                   from './design/pages/Register';
import Home                       from './design/pages/Home';
import ResetRequest               from './design/pages/ResetRequest';
import ResetExecute               from './design/pages/ResetExecute';
import ProfilePage                from './design/pages/Profile/Profile';
import Payment                    from './design/pages/Payment';
import PaymentLogs                from './design/pages/PaymentLogs';
import AccountantCourse           from './design/pages/AccountantCourse';
import SomethingWentWrong         from './design/pages/SomethingWentWrong';
import VerifyEmail                from './design/pages/VerifyEmail';
import ImpersonationDirector      from './design/molecules/ProductEngineDirectors/ImpersonationDirector';
import EconomicIntegration        from './design/pages/EconomicIntegration';
import ArchiveDetail              from './design/pages/ArchiveDetail';
import InvitationBroker           from './design/pages/InvitationBroker';
import GettingStarted             from './design/pages/GettingStarted';
import Bye                        from './design/pages/Bye';
import ImpersonationSearch        from './design/pages/ImpersonationSearch';
import ClearTokens                from './design/pages/ClearTokens';
import CancelNews                 from './design/pages/CancelNews';
import ProductEngineAppLauncher   from './design/pages/ProductEngineAppLauncher';
import ProductDirector            from './design/molecules/ProductEngineDirectors/ProductDirector';
import DevDirector                from './design/molecules/ProductEngineDirectors/DevDirector';
import DevAndLocalEconomicProxy   from './design/pages/EconomicIntegration/DevAndLocalEconomicProxy';

class App extends React.Component {
    state = {
        ready: false,
        hasError: false,
        isSystemUpdateOngoing: false,
    };

    componentDidMount = async () => {
        await onStartup();

        this.systemUpdateSubscription = systemUpdate.subscribe(isSystemUpdateOngoing => {
            this.setState({ isSystemUpdateOngoing });
        });

        this.routes = (
            <Switch>
                <Redirect exact path='/profil' to='/indstillinger' />
                <NoAuthRoute path='/nulstil/afslut' component={ResetExecute} />
                <NoAuthRoute path='/nulstil' component={ResetRequest} />
                <NoAuthRoute path='/register' component={Register} />
                <NoAuthRoute path='/login' component={Login} /> 
                <NoAuthRoute path='/bye' component={Bye} />
                <AuthRoute path='/getting-started' component={GettingStarted} skipEntityCheck />
                <AuthRoute path='/betaling/credits' component={Payment} />
                <AuthRoute path='/betaling/raadgiverkursus' component={Payment} />
                <AuthRoute path='/betaling/manuel-gennemgang/:taxYear' component={Payment} />
                <AuthRoute path='/betaling/opstartshjaelp' component={Payment} />
                <AuthRoute path='/betaling/opgrader/:productID/:taxYear/:packageTier' component={Payment} />
                <AuthRoute path='/betaling/:productID/:taxYear' component={Payment} />
                <AuthRoute exact path='/' component={Home} />
                <AuthRoute path='/indstillinger' component={ProfilePage} />
                <AuthRoute path='/raadgiverkursus/:page' component={AccountantCourse} />
                <AuthRoute path='/service/:productID/:type/:year/:label/:page' component={ProductDirector} />
                <Redirect from='/service/:productID/:type/:year/:page' to='/service/:productID/:type/:year/regular/:page' />
                <AuthRoute path='/service/:productID' component={ProductEngineAppLauncher} />
                <AuthRoute path='/product/:product/:label/:page' component={DevDirector} />
                <Redirect from='/product/:product/:page' to='/product/:product/regular/:page' />
                <AuthRoute path='/impersonation' component={ImpersonationDirector} />
                <AuthRoute path='/impersonation-search' component={ImpersonationSearch} />
                <AuthRoute path='/betalinger/:erp' component={PaymentLogs} />
                <AuthRoute path='/ukendt-fejl' component={SomethingWentWrong} />
                <AuthRoute path='/emailverificering' component={VerifyEmail} />
                <Route path='/integrations/e-conomic' component={EconomicIntegration} />
                {IS_PRODUCTION && (
                    <Route path='/integrations/dev-local-economic-proxy' component={DevAndLocalEconomicProxy} />
                )}
                <Route path='/invitation/:token' component={InvitationBroker} />
                <Route path='/unsubscribe' component={CancelNews}/>
                <Route path='/clear' component={ClearTokens} />
                <AuthRoute path='/arkiv/:productID/:variation/:taxYear' component={ArchiveDetail} />
                <Redirect from='*' to='/'/>
            </Switch>
        );

        this.setState({ ready: true, isSystemUpdateOngoing: systemUpdate.getStatus() });
    };

    componentWillUnmount = () => {
        systemUpdate.unsubscribe(this.systemUpdateSubscription);
    };

    componentDidCatch = (error, errorInfo) => {
        handleComponentError({
            error,
            errorInfo,
            history: this.props.history,
        });
    };

	onURLChange = newURL => {
        googleTagManager.pushPageViewEvent(newURL);
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.onURLChange(this.props.location.pathname);
        }
    };

    render = () => {
        const { isSystemUpdateOngoing, ready } = this.state;

        if (!ready) {
            return null;
        }

        const content = (
            isSystemUpdateOngoing
                ? systemUpdate.renderMessage()
                : <CreditsProvider>{this.routes}</CreditsProvider>
        );

        return (
            <>
                <RegularTawkTo />
                {content}
            </>
        );
    };
}

export default withRouter(App);