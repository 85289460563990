import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import { refreshTokens } from 'network/fetch/refreshTokens';
import { createReportingEntity, createOwnerShipEntity } from 'http/accounts'
import { setChosenUser } from 'util/token';
import { useRootUser } from 'util/useUser';
import ReportingEntityFormFields from 'design/molecules/ReportingEntityFormFields';

const CreateEntity = () => {
    const [working, setWorking] = useState(false);
    const [newEntity, setNewEntity] = useState({});
    
    const history = useHistory();
    const user = useRootUser();

    const shouldAddOtherCompanies = (entity) => { 
        return entity.daughterCompanies.length > 0 ;
    }

    const doAddOtherCompanies = async (company) => {
    
    try {
        
        if(company.daughterCompanies.length > 0) {
            for (const daughter of company.daughterCompanies) {
                await createOwnerShipEntity(user.login.loginEmail, daughter);
            }
        }

        if(company.ownerCompanies.length > 0) {
            for (const owner of company.ownerCompanies) {
                await createOwnerShipEntity(user.login.loginEmail, owner);
            }
        }

        } catch (e) {
           return e.message || 'Der opstod en fejl under oprettelsen af andre selskaber';

        }
    }

    const doCreateNewEntity = async () => {
        setWorking(true);

        try {
            // try creating new entity
            const { id, displayName } = await createReportingEntity(newEntity.data);

            if (shouldAddOtherCompanies(newEntity)){
                await doAddOtherCompanies(newEntity);
            }

            // mark newly created user as chosen & update access token
            setChosenUser(id);
            await refreshTokens();

            // toast & redirect to home
            toast.success(
                <span><b>{displayName}</b> blev oprettet!</span>,
            );
            history.push('/');
        } catch (e) {
            const errorMessage = (
                e.status === 400 ?
                e.message :
                'Der opstod en fejl under oprettelsen'
            );
            
            toast.error(errorMessage);
        }

        setWorking(false);
    };

    return (
        <Form loading={working}>
            <ReportingEntityFormFields
                isPrivat={user.isSegmentPrivat()}
                isErhverv={user.isSegmentErhverv()}
                onChange={setNewEntity}
            />
            <Form.Field>
                <Button
                    disabled={!newEntity.valid}
                    onClick={doCreateNewEntity}
                    content='Opret'
                    icon='signup'
                    primary
                    fluid
                />
            </Form.Field>
        </Form>
    );
};

export default CreateEntity;