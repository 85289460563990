import { statusesMap } from 'design/molecules/AppLauncher/userStatuses';
import get from 'lodash.get';

export const STATUS_FACT_ID = 'STATUS';
export const FILES_FACT_ID = 'FILES';

export const FLOW_CONTEXT_TAG = 'FLOW_CONTEXT';
export const TAX_REPORTED_TAG = 'TAX_REPORTED';
export const ACCOUNT_PLANS_TAG = 'AccountPlans';

/**
 * Extracts the account plans fact from the fact map
 * @param {*} values fact map
 */
export function getAccountPlansFact(values) {
    if (!values) {
        return null;
    }
    return Object.values(values).find(v => v.tag === ACCOUNT_PLANS_TAG);
}

export function getStatusFromFact(fact) {
    const statusID = fact?.value?.string;
    return statusesMap[statusID] || statusesMap.not_started;
}

export function extractStatus(values = {}) {
    return getStatusFromFact(values[STATUS_FACT_ID]);
}

export function getTaxReportedFact(values) {
    return Object.values(values).find(fact => {
        return fact?.tag?.startsWith(TAX_REPORTED_TAG);
    });
}

export function isTaxReported(values) {
    return getTaxReportedFact(values)?.value?.boolean || false;
}

/**
 * Creates a fact map for a resource field.
 * 
 * @param {*} resource Resource for which to create a fact map for
 * @param {*} resourceRenderSlug Resource slug
 * @param {*} field 
 * @param {*} values Fact values of the product
 * 
 * @returns Created fact map for a resource
 */
export function createResourceFactMap(resource, resourceRenderSlug, field, values) {
    const resourceFactMap = {};

    // prepare questions
    for (let question of field.questions || []) {
        const value = get(values, `${question.id}.value.values.${resourceRenderSlug}`);
        if (value !== undefined) {
            resourceFactMap[question.id] = {
                value: {
                    [question.mainProperty]: value,
                },
            };
        }

        const lastYearValue = get(values, `${question.id}.value.lastYearValues.${resourceRenderSlug}`);
        if (lastYearValue !== undefined) {
            resourceFactMap[question.id] = resourceFactMap[question.id] || { value: {} };
            resourceFactMap[question.id].value.lastyear = lastYearValue;
        }
    }

    // prepare calculation results
    const calculationResults = get(resource, 'calculationResults', {});
    for (let [tag, result] of Object.entries(calculationResults)) {
        resourceFactMap[tag] = {
            value: {
                number: Number(result),
            },
        };
    }

    return resourceFactMap;
}
