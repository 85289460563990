import { confirmPayment } from 'http/payment';
import { refreshTokens } from 'network/fetch/refreshTokens';

/**
 * Handles SCA for Stripe payments
 * @param {*} stripeClient - Stripe client provided by StripeElements
 * @param {*} purchaseResult - Data returned from requesting a purchase 
 */
const handleSCA = async (stripeClient, { requires3DConfirmation, clientSecret, token }) => {
    // skip if 3D confirmation isn't required
    if (!requires3DConfirmation) {
        return { success: true };
    }

    const result = await stripeClient.handleCardPayment(clientSecret);

    // 3D confirmation failed
    if (result.error) {
        return {
            success: false,
            message: result.error.message,
        };
    }

    // 3D confirmation success
    try {
        await confirmPayment(token);
    } catch (e) {
        const { accessWasGranted, invoiceWasSent } = e.data || {};

        const additionalErrorMessages = [];

        if (!accessWasGranted) {
            additionalErrorMessages.push('Der blev ikke åbnet for adgangen til det købte produkt');
        } else {
            // refresh access token to get new product access
            await refreshTokens();
        }

        if (!invoiceWasSent) {
            additionalErrorMessages.push('Der blev ikke sendt en faktura til din mail');
        }

        return {
            success: false,
            message: (
                <>
                    Din betaling blev modtaget, men der opstod følgende fejl:
                    {additionalErrorMessages.map(message => {
                        return <div>&bull; {message}</div>
                    })}
                    Kontakt os venligst i supporten, så vi kan hjælpe dig videre herfra.
                </>
            ),
        };
    }

    return { success: true };
};

export default handleSCA;