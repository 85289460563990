import React, { useState } from 'react';
import { Divider, Icon, Popup, Loader, Transition } from 'semantic-ui-react';
import { moms } from 'util/moms';
import AnimatedNumber from 'design/atoms/AnimatedNumber';
import ColoredText from 'design/atoms/ColoredText';
import { doPriceCalculations } from './doPriceCalculations';
import { ShoppingCartSegment } from './ShoppingCart';

const OrderLine = ({ description, price, strong, ...otherProps }) => {
    return (
        <div
            {...otherProps}
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
                fontWeight: strong ? 'bold' : undefined,
            }}
        >
            <div
                style={{
                    flex: 1,
                    marginRight: '20px',
                    whiteSpace: 'normal',
                }}
            >
                {description}
            </div>
            <div style={{ whiteSpace: 'nowrap' }}>
                {price}
            </div>
        </div>
    );
};

function CheckoutAmount ({ icon, label, amount, strong, vat, sub, onClear }) {
    const [isHovered, setIsHovered] = useState(false);

    if (vat) {
        amount = moms(amount);
    }

    return (
        <OrderLine
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            strong={strong}
            description={
                <>
                    {isHovered && onClear && (
                        <Popup
                            position='top center'
                            content='Fjern fra kurv'
                            trigger={
                                <Icon
                                    name='x'
                                    color='red'
                                    link
                                    onClick={onClear}
                                />
                            }
                        />
                    )}
                    {icon && <Icon name={icon} />}
                    {label}
                </>
            }
            price={<>{sub && '-'}<AnimatedNumber number={amount} price /> kr.</>}
        />
    );
}

function CheckoutDiscount ({ percentage, amount, description, loading }) {
    const percentageDisplayer = percentage && (
        <ColoredText
            bold
            color='green'
            content={`-${percentage} %`}
        />
    );

    const greenTag = amount && (
        <Icon
            loading={loading}
            name='tag'
            color='green'
        />
    );

    return (
        <CheckoutAmount
            label={<ColoredText>{greenTag}{description || <>Rabat {percentageDisplayer}</>}</ColoredText>}
            amount={amount}
            sub
        />
    );
}

function PaymentOverview({ orderLines, working, proactiveVat, extraContent, discount }) {
    const results = doPriceCalculations({
        orderLines,
        proactiveVat,
        discount,
    });

    const cartHasItems = orderLines.length > 0;

    const renderCartItems = () => {
        return orderLines.map(({ icon, description, price, beforeDiscountPrice, discount, discountLabel, discountAmount, onClear }) => {
            // format order line
            let discountLine;
            let displayPrice;
            if (discount || discountLabel || discountAmount) {
                // seperate line for the discount amount
                discountLine = <CheckoutDiscount
                    description={discountLabel}
                    percentage={discount}
                    amount={proactiveVat ? moms(discountAmount) : discountAmount}
                />;

                // display price before applied discount
                displayPrice = beforeDiscountPrice;
            } else {

                // display regular price
                displayPrice = price;
            }

            return <>
                <CheckoutAmount
                    label={description}
                    icon={icon}
                    amount={proactiveVat ? moms(displayPrice) : displayPrice}
                    onClear={onClear}
                />
                {discountLine}
            </>;
        });
    };

    const workingIndicator = (
        <Transition visible={!!working}>
            <Loader inline active size='small' />
        </Transition>
    );

    return (
        <ShoppingCartSegment title='Indkøbskurv' icon='shopping cart' rightContent={workingIndicator}>
            {renderCartItems()}
            {cartHasItems && <Divider />}
            <CheckoutAmount
                label='Subtotal'
                amount={results.subtotal}
            />
            {discount?.active && (
                <CheckoutDiscount
                    description={discount.name}
                    amount={discount.purchaseAmountOff}
                />
            )}
            {!proactiveVat && (
                <CheckoutAmount
                    label='Moms' 
                    amount={results.vat}
                />
            )}
            <Divider />
            <CheckoutAmount
                label={`Total ${proactiveVat ? ' (inkl. moms)' : ''}`}
                amount={results.total}
                strong
            />
            {extraContent}
        </ShoppingCartSegment>
    );
}

export default PaymentOverview;