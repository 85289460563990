import React, { useCallback, useEffect, useState } from "react";
import { Menu, Icon, Popup, Divider, Image, Feed, Modal, ModalContent, ModalHeader, ModalDescription, Button, TransitionablePortal } from "semantic-ui-react";
import { flagNotificationsAsSeen, listUnseenNotifications, listSeenNotifications, GetlistUnseenNotifications, DeleteNotification } from "http/notifications";
import { getEmailNotifications, flagEmailNotificationsAsSeen } from "http/email";
import { API_LOCATION } from "config/config";
import { Media } from "design/atoms/Media";
import { formatDate } from "util/format/DateTime";
import { useRootUser } from "util/useUser";
import userSessionStore from "util/userSessionStore";
import { ACCESS_TOKEN_CHANGED, isTokenExpired } from "util/token";
import { subscribe, unsubscribe } from "util/pubsub";

const globalNotificationsState = {
  notifications: [],
};

const useNotifications = () => {
  const [notifications, setNotifications] = useState(
    globalNotificationsState.notifications
  );

  const user = useRootUser();
  const loginID = user.login.id;

  /* Since notificatioons can come from 2 places we need to grab them and put them together in one pile */
  const fetchAndSet = useCallback(async () => {
    const unseenNotifications = await listUnseenNotifications();
    const seenNotifications = await listSeenNotifications();
    const popNotifications = await getEmailNotifications(loginID);
  
    const allNotifications = [
      ...unseenNotifications,
      ...seenNotifications,
      ...popNotifications,
    ];
  
    allNotifications.sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );
  
    /* Filter out duplicate notifications */
    const uniqueNotifications = {};
    allNotifications.forEach((notification) => {
      if (!uniqueNotifications[notification._id]) {
        uniqueNotifications[notification._id] = notification;
      }
    });
  
    const recentNotifications = Object.values(uniqueNotifications).slice(0, 5);
  
    globalNotificationsState.notifications = recentNotifications;
    setNotifications(globalNotificationsState.notifications);
  }, [loginID]);

  useEffect(() => {
    fetchAndSet();
  }, [fetchAndSet]);

  const markNotificationsAsSeen = async () => {
    await GetlistUnseenNotifications();
    await getEmailNotifications(loginID);

    await flagNotificationsAsSeen(notifications.map(({ id }) => id));
    await flagEmailNotificationsAsSeen(notifications.map(({ _id }) => _id));

    globalNotificationsState.notifications = [];
  };

  const deleteNotifcation = async (id) => {
    await DeleteNotification(id);
    fetchAndSet();
  };

  return [
    notifications,
    markNotificationsAsSeen,
    deleteNotifcation,
    fetchAndSet,
  ];
};

const NotificationCenter = () => {
  const [notifications, markNotificationsAsSeen, deleteNotifcation, refetch] = useNotifications();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [accessToken, setAccessToken] = useState(userSessionStore.get('accessToken'));

  useEffect(() => {
    const subscriptionToken = subscribe(ACCESS_TOKEN_CHANGED, () => {
      const newToken = userSessionStore.get('accessToken');
      setAccessToken(newToken);
    });

    return () => {
      unsubscribe(subscriptionToken);
    };
  }, []);

  useEffect(() => {
    if (!accessToken) {
      return;
    }
      
    if (isTokenExpired(accessToken)) {
      return;
    }

    const eventSourceLocation = `${API_LOCATION}notifications/stream?jwt=${accessToken}`;
    const eventSource = new EventSource(eventSourceLocation);
    eventSource.onmessage = () => refetch();

    return () => {
      eventSource.close();
    };
  }, [refetch, accessToken]);

  /* Since both a Pop-up/Domain notification is shown with modal we need different functionality to handle open and close*/
  const handleClose = async () => {
    setIsOpen(false);
    if (notifications.length > 0) {
      await markNotificationsAsSeen();
      refetch();
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsOpen(true);
  };

  const handleModalOpen = (notification) => {
    setSelectedNotification(notification);
    setIsModalOpen(true);
    setIsOpen(false);
  };

  const handleModalOnce = async (id) => {
    await markNotificationsAsSeen();
    await deleteNotifcation(id);
  };

  const renderSeen = (e) => {
    const textElements = e.target.getElementsByTagName("p");

    for (let i = 0; i < textElements.length; i++) {
      textElements[i].style.color = "black";
    }
  };

  const renderContent = () => {
    if (notifications.length === 0) {
      return <span>Ingen nye notifikationer</span>;
    }
/* Here is the feed, depending on what notification type it renders differently  */
    return (
      <>
      <Feed size="small">
        {notifications.map((notification) => (
        <>
          <Feed.Event onMouseOver={renderSeen}
          style={{
            cursor: "pointer",  
          }}
          >
          <Feed.Content>
            <Feed.Summary
            onClick={() => {
              if (notification.type === "newsletter") {
              handleModalOpen(notification);
              } else {
              window.open(notification.link, "_blank");
              return;
              }
            }}
            style={{
              color: notification.seen ? "black" : "#46C476",
            }}
            >
            <p
              style={{
              color: notification.seen ? "black" : "#46C476",
              fontSize: "14px",
              fontWeight: "bold",
              cursor: "pointer",
              }}
            >
              {notification.title}
            </p>
            </Feed.Summary>
            <Feed.Extra
            text
            onClick={() => {
              if (notification.type === "newsletter") {
              handleModalOpen(notification);
              }
              if (notification.type === "default") {
              window.open(notification.link, "_blank");
              } else {
              return;
              }
            }}
            style={{
              color: "black",
              fontSize: "12px",
              paddingRight: "10px",
              borderRight: "5px solid #46C476",
            }}
            >
            <div
              dangerouslySetInnerHTML={{
              __html:
                notification.type === "default"
                ? notification.messageBody + "..." + `<a style="color: #46C476;"> Læs mere </a>`
                : notification.messageBody.substring(0, 135) +
                  "..." +
                  `<a style="color: #46C476;"> Læs mere </a>`,
              }}
            />
            </Feed.Extra>

            <Feed.Date
            style={{
              marginTop: "10px",
            }}
            >
            <p
              style={{
              textAlign: "right",
              fontSize: "10px",
              color: notification.seen ? "black" : "#46C476",
              }}
            >
              {formatDate(notification.timestamp, "da-DK")}
            </p>
            </Feed.Date>
          </Feed.Content>
          </Feed.Event>
          <Divider />
        </>
        ))}
      </Feed>
      </>
    );
  };

  const renderBadge = () => {
    const unseenNotifications = notifications.filter(
      (notification) => !notification.seen
    );
    if (unseenNotifications.length === 0) {
      return null;
    }

    return (
      <div
        children={
          unseenNotifications.length > 5 ? "+" : unseenNotifications.length
        }
        style={{
          right: -8,
          top: -8,
          position: "absolute",
          background: "red",
          borderRadius: "50%",
          fontSize: "60%",
          width: "12px",
          height: "12px",
          color: "white",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  };

  const unseenNotifications = notifications.filter(
    (notification) => !notification.seen
  );

  /* Email */
  const htmlContent = selectedNotification ? selectedNotification.html : "";
  const popNotifications = unseenNotifications.filter((notification) => notification.type === "domain");
  let popLink = popNotifications.length > 0 ? popNotifications[0].link : "";

  return (
    <Media gte="tablet">
      <Popup
        trigger={
          <Menu.Item active={isOpen}>
            <div style={{ position: "relative" }}>
              {renderBadge()}
              <Icon
                fitted
                name="bell"
                color={
                  notifications.some((notification) => !notification.seen) &&
                  !isOpen
                    ? "green"
                    : undefined
                }
              />
            </div>
          </Menu.Item>
        }
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={handleClose}
        content={renderContent()}
        position="bottom right"
        on="click"
        basic
      />
      <Modal
        basic
        open={isModalOpen}
        size="large"
        onClose={handleModalClose}
        dimmer={true}
      >
        <Icon
          name="close"
          color="green"
          onClick={handleModalClose}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            top: "1.5rem",
            right: "1.5rem",
            fontSize: "1.5rem",
          }}
        />

        <ModalContent>
          <div>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        </ModalContent>
      </Modal>

      {popNotifications.length > 0 && (
        <TransitionablePortal open>
          <Modal
            size="tiny"
            defaultOpen
            dimmer="dimmer"
            onClose={() => handleModalOnce(popNotifications[0]._id)}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              padding: "1.5rem",
              textSizeAdjust: "none",
            }}
          >
            <Icon
              name="close"
              color="green"
              onClick={() => handleModalOnce(popNotifications[0]._id)}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                top: ".5rem",
                right: ".5rem",
                fontSize: "1.5rem",
              }}
            />

            <ModalContent>
              <Image
                size="large"
                verticalAlign="center"
                style={{
                  cursor: "pointer",
                  margin: "auto",
                }}
                onClick={() =>
                  window.open("https://www.digitalrevisor.nu/", "_blank")
                }
                src={`${popNotifications[0].image}`}
              />
            </ModalContent>
            <ModalHeader
              style={{
                fontSize: "2rem",
                fontWeight: "bold",
                color: "black",
                border: "none",
                textAlign: "center",
              }}
              content={popNotifications[0].title}
            ></ModalHeader>
            <ModalContent
              style={{
                fontSize: "14px",
                color: "black",
                textAlign: "center",
                whiteSpace: "pre-line"

              }}
              content={popNotifications[0].messageBody}
            ></ModalContent>
            <ModalDescription
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                color="green"
                fluid
                textAlign="center"
                onClick={() => window.open(`${popLink}`, "_blank")}
              >
                Læs mere
              </Button>
            </ModalDescription>
          </Modal>
        </TransitionablePortal>
      )}
    </Media>
  );
};

export default NotificationCenter;
