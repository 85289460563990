import { toUpper } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import { getCompanyRelations } from 'http/cvr';

const RelationDropdown = ({ setSelectedOwnerCompanies, setSelectedDaughterCompanies, showDropdown, onChange, data }) => {
  const [ownerShip, setOwnerShip] = useState([]);
  const [ownerCompanies, setOwnerCompanies] = useState([]);
  const [daughterCompanies, setDaughterCompanies] = useState([]);

  const getPlaceholderLabel = (type) => {
    return toUpper(`${type}selskaber`);
  }
  const getLabel = (type) => {
    return `${type}selskaber`;
  }

  useEffect(() => {
    const fetchRelations = async () => {
      const relations = await getCompanyRelations(data.value);
      setOwnerShip(relations);
    };

    fetchRelations();
  }, [data]);

  useEffect(() => {
    const isPresent = (companies) => {
      return companies.find(company => String(company.CVR) === data.value);
    }

    if (ownerShip.owners && !isPresent(ownerShip.owners)) {
      setOwnerCompanies(
        ownerShip.owners.map((company) => ({
          key: company.CVR,
          text: company.name,
          value: {
            name : company.name,
            cvr : company.CVR,
          }
        }))
      );
    }

    if (ownerShip.daughterCompanies && !isPresent(ownerShip.daughterCompanies)) {
      setDaughterCompanies(
        ownerShip.daughterCompanies.map((company) => ({
          key: company.CVR,
          text: company.name,
          value: {
            name : company.name,
            cvr : company.CVR,
          }

        }))
      );
    }
  }, [ownerShip, data]);




  return (
    <>
      {showDropdown && ownerCompanies.length > 0 && (
        <Form.Field style={{ marginTop: '1rem' }}>
          <label>{getLabel('Ejer')}</label>
          <Dropdown
            multiple
            selection
            fluid
            options={ownerCompanies}
            placeholder={getPlaceholderLabel('ejer')}
            onChange={(_, { value }) => {
              setSelectedOwnerCompanies(value);
              onChange && onChange({
                cvr: data.value,
                ownerShip: ownerShip,
                selectedOwnerCompanies: value,
                valid: true,
              });
            }}
          />
        </Form.Field>
      )}

      {showDropdown && daughterCompanies.length > 0 && (
        <Form.Field>
          <label>{getLabel('Datter')}</label>
          <Dropdown
            multiple
            selection
            fluid
            options={daughterCompanies}
            placeholder={getPlaceholderLabel('datter')}
            onChange={(_, { value }) => {
              setSelectedDaughterCompanies(value);
              onChange && onChange({
                cvr: data.value,
                ownerShip: ownerShip,
                selectedDaughterCompanies: value,
                valid: true,
              });
            }}
          />
        </Form.Field>
      )}
    </>
  );
};

export default RelationDropdown;