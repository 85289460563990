import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import CVRSearch from 'design/molecules/CVRSearch';
import { JOINT_TAX } from 'flags';

const ReportingEntityFormFields = ({ isPrivat, isErhverv, onChange }) => {
    const [formData, setFormData] = useState({});
    const [daughterCompanies, setDaughterCompanies] = useState({});
    const [ownerCompanies, setOwnerCompanies] = useState([]);

    useEffect(() => {
        const requiredProps = (
            isErhverv ?
                ['cvr'] :
                ['firstname', 'lastname']
        );

        onChange && onChange({
            valid: requiredProps.every(prop => formData[prop]),
            data: formData,
            daughterCompanies,
            ownerCompanies,
        });
    }, [formData, onChange, isErhverv, daughterCompanies, ownerCompanies]);

    const onValueChange = useCallback((path, val) => {
        const newFormData = { ...formData };
        newFormData[path] = val;
        setFormData(newFormData);
    }, [formData, setFormData]);

    const onCVRChange = useCallback(({ valid, cvr, selectedDaughterCompanies, selectedOwnerCompanies }) => {
        setDaughterCompanies(selectedDaughterCompanies);
        setOwnerCompanies(selectedOwnerCompanies);
        onValueChange('cvr', valid ? cvr : null);
    }, [onValueChange]);

    const renderFormValuesPrivate = () => {
        return <>
            <Form.Field>
                <label>Fornavn</label>
                <Input
                    autoFocus
                    fluid
                    onChange={(_, { value }) => onValueChange('firstname', value)}
                />
            </Form.Field>
            <Form.Field>
                <label>Efternavn</label>
                <Input
                    fluid
                    onChange={(_, { value }) => onValueChange('lastname', value)}
                />
            </Form.Field>
        </>;
    };

    const renderFormValuesBusiness = () => {

        if (JOINT_TAX()) {
            return (
                <CVRSearch
                    onChange={onCVRChange}
                    showDropdown={true}
                />
            )
        }

        return (
            <CVRSearch
                onChange={onCVRChange}
            />
        );
    };

    if (isPrivat) {
        return renderFormValuesPrivate();
    }

    if (isErhverv) {
        return renderFormValuesBusiness();
    }

    return null;
};

export default ReportingEntityFormFields;