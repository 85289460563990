import { HTTPClient } from 'network/fetch';
import { refreshTokens } from 'network/fetch/refreshTokens';
import { makeQueryParams } from 'util/QueryParams';

const client = new HTTPClient({ prefix: 'erp-broker/api' });

export function registerERP(credentials) {
    return client.postResource('register', {
        ...credentials,
    });
}

export async function unregisterERP() {
    await client.deleteResource('register');
    await refreshTokens();
}

export function getMetadata(target, taxYear) {
    return client.getResource(`booking/tax-target-metadata?target=${target}&taxYear=${taxYear}`);
}

export function book(booking, selectedJournalID = '') {
    return client.postResource('booking/book' + makeQueryParams({ selectedJournalID }), [
        booking,
    ]);
}

export function bookMultiple(bookings, selectedJournalID = '') {
    return client.postResource('booking/book' + makeQueryParams({ selectedJournalID }), bookings);
}

export function getEntriesInPeriodByAccountNumber(accountNumber, from, to) {
    return client.getResource(`/accounts/${accountNumber}/entries?from=${from}&to=${to}`);
}

export function getEntryAttachmentData(accountNumber, entryID, attachmentID, attachmentType) {
    return client.getResource(`/accounts/${accountNumber}/entries/${entryID}/attachments/${attachmentID}/data?attachmentType=${attachmentType}`);
}

/**
 * @param {*} accountNumber 
 * @param {string[]} entryIds 
 */
export function getAttachmentsForEntriesInPeriod(accountNumber, from, to, entryIds) {
    return client.getResource(`/accounts/${accountNumber}/entries/${entryIds.join(',')}/attachments?from=${from}&to=${to}`);
}

export function getJournals() {
    return client.getResource('/journals');
}