const getPeriodDatesAsISO = ({ start, end }) => {
    const [from, to] = [start, end].map(({ year, month, day }) => {
        return [year, month, day].map(part => {
            return part.toString().padStart(2, '0');
        }).join('-');
    });

    return { from, to };
};

export default getPeriodDatesAsISO;