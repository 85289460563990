import React from 'react';
import { getParam } from 'util/QueryParams';
import useDeferredRedirect from 'util/useDeferredRedirect';
import usePaymentState from '../shared/usePaymentState';
import PaymentShell from '../shared/PaymentShell';
import PaymentPanels from '../shared/PaymentPanels';
import PaymentOverview from '../shared/PaymentOverview';
import HighFive from '../shared/HighFive';
import { refreshTokens } from 'network/fetch/refreshTokens';

export const STARTER_HELP_ID = 'OPHJERHVERV';

export const starterHelpOrderLineItem = {
    icon: 'rocket',
    description: 'Opstartshjælp',
    price: 1000,
    metadata: {
        productPurchase: {
            type: 'ONETIME_PAYMENT',
            id: STARTER_HELP_ID,
        },
    },
};

const PaymentFlowStarterHelp = () => {
    const orderLines = [starterHelpOrderLineItem];

    const paymentState = usePaymentState({
        orderLines,
    });

    useDeferredRedirect(paymentState.paymentDone, getParam('redirect') || '/');

    const handlePayment = async () => {
        const { success } = await paymentState.performPaymentRequest();
        if (!success) {
            return;
        }

        await refreshTokens();
    };

    let children;
    if (paymentState.paymentDone) {
        children = <HighFive />
    } else {
        children = (
            <PaymentPanels
                leftPanel={paymentState.renderPaymentMethod()}
                rightPanel={
                    <PaymentOverview
                        orderLines={orderLines}
                        extraContent={(
                            <div>
                                {paymentState.renderPayButton(handlePayment)}
                                {paymentState.renderError()}
                            </div>
                        )}
                    />
                }
            />
        );
    }

    return (
        <PaymentShell
            paymentDone={paymentState.paymentDone}
            title='Køb opstartshjælp'
            disabled={paymentState.working}
            children={children}
        />
    );
};

export default PaymentFlowStarterHelp;