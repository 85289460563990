import ReactDOM          from 'react-dom/client';
import React             from 'react';
import { Elements }      from '@stripe/react-stripe-js';
import { loadStripe }    from '@stripe/stripe-js';
import { Router }        from 'react-router-dom';
import { STRIPE_KEY }    from 'config/config';
import { ShellProvider } from 'design/molecules/ProductPage';
import browserHistory    from 'util/browserHistory';
import App               from './App';

import './styles/react-slick.css';

// load stripe components
const stripePromise = loadStripe(STRIPE_KEY);

ReactDOM.createRoot(document.getElementById('root')).render(
    <ShellProvider>
        <Elements stripe={stripePromise}>
            <Router history={browserHistory}>
                <App className='wrapper' />
            </Router>
        </Elements>
    </ShellProvider>
);
