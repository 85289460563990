import React, { useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import useUser, { useRootUser } from 'util/useUser';
import { ProductPageWithSubHeader } from 'design/molecules/ProductPage';
import { ESG_OPEN_FOR_ALL, SPLIT_BOOKKEEPER_ACCOUNTANT } from 'flags';

import PurchaseNotAllowed from './PurchaseNotAllowed';
import CreditsTaxYearUnlocker from './CreditsTaxYearUnlocker';
import PaymentFlowCredits from './PaymentFlowCredits';
import PaymentSelectAccountantRole from './PaymentSelectAccountantRole';
       
import PaymentFlowAccountantCourse from './PaymentFlowAccountantCourse';
import PaymentFlowPackage from './PaymentFlowPackage';
import PaymentFlowUpgradePackage from './PaymentFlowUpgradePackage';
import PaymentFlowStarterHelp from './PaymentFlowStarterHelp';
import PaymentFlowManualHoldingReview from './PaymentFlowManualHoldingReview';
import { shoppingCartEnterd } from '../../../http/hubspot';

const AccountantPaymentRoutes = () => {
    const { productID, taxYear } = useParams();
    const { pathname } = useLocation();
    const user = useUser();

    if (pathname.startsWith('/betaling/credits')) {
        const isBookkeeper = (user.roles.indexOf("bookkeeper") > -1);
        const isassistance = (user.roles.indexOf("assistance") > -1);
        
        if(SPLIT_BOOKKEEPER_ACCOUNTANT() && (!isBookkeeper && !isassistance)) {
            return <PaymentSelectAccountantRole />;
        } else {
            return <PaymentFlowCredits />;
        }
    }

    if (pathname.startsWith('/betaling/raadgiverkursus')) {
        return <PaymentFlowAccountantCourse />;
    }

    return (
        <ProductPageWithSubHeader subHeader={`Få fuld adgang ∙ Skatteår ${taxYear}`}>
            <CreditsTaxYearUnlocker productID={productID} taxYear={taxYear} />
        </ProductPageWithSubHeader>
    );
};

const RegularUserPaymentRoutes = () => {
    const { productID } = useParams();
    const { pathname } = useLocation();
    const user = useUser();
    const history = useHistory();

    if (productID === 'solceller') {
        return (
            <ProductPageWithSubHeader subHeader='Køb ikke tilladt'>
                <PurchaseNotAllowed 
                    header='Køb ikke tilladt'
                    msg='Vi tilbyder desværre ikke længere dette produkt'
                />
            </ProductPageWithSubHeader>
        );
    }
    
    if (productID === 'esg' && !ESG_OPEN_FOR_ALL()) {
        history.push('/');
        return null;
    }

    if (pathname.startsWith('/betaling/opgrader')) {
        return <PaymentFlowUpgradePackage />;
    }

    if (pathname.startsWith('/betaling/opstartshjaelp')) {
        return <PaymentFlowStarterHelp />;
    }

    if (pathname.startsWith('/betaling/manuel-gennemgang')) {
        return <PaymentFlowManualHoldingReview />;
    }

    return <PaymentFlowPackage />;
};

const Payment = () => {
    const user = useRootUser();
    const { productID } = useParams();

    useEffect(() => {
        shoppingCartEnterd(productID)
            .catch(error => console.error('Failed to process shopping cart entry:', error));
    }, [productID]);

    if (user.isAccountant()) {
        return <AccountantPaymentRoutes />;
    }

    if(user.isAkademi()) {
        return <AccountantPaymentRoutes />;

    }

    return <RegularUserPaymentRoutes />;
};

export default Payment;