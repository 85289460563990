import React, { Component } from 'react';
import { Form, Icon, Modal, Header, Message, Input, Button } from 'semantic-ui-react';
import { registerClient } from 'http/accounts';
import { refreshTokens } from 'network/fetch/refreshTokens';
import i18n from 'i18n/pages/Register';
import CVRSearch from '../CVRSearch';
import { toast } from 'react-toastify';
import RapportLanguagePicker from 'design/atoms/RapportLanguagePicker';

const initialState = {
    fetching: false,
    error: null,
    errorColor: '',
    cvr: null,
    rapportLanguage: 'da-DK',
    ownerShip: {},
    firstname: null,
    lastname: null,
    selectedDaughterCompanies: [],
    selectedOwnerCompanies: [],
    selectedState: '',
};

class ModalContent extends Component {
    state = { ...initialState };

    close = () => this.props.close();

    setFetching = fetching => {
        return new Promise(resolve => {
            this.setState({ fetching }, resolve);
        });
    };

    onFormSubmit = async () => {
        const { isPrivate, onClientCreated, selectedRole } = this.props;    
        try {
            const {
                firstname,
                lastname,
                cvr,
                rapportLanguage,
                selectedDaughterCompanies,
                selectedOwnerCompanies,
            } = this.state;
    
            const clientData = { 
                isPrivate,
                ...(isPrivate 
                    ? { firstname, lastname }
                    : { cvr, rapportLanguage }
                )
            };
    
            const registerClientWithData = async (data) => {
                await registerClient(data);
            };
    
            if (selectedDaughterCompanies?.length > 0) {
                for (const company of selectedDaughterCompanies) {
                    const daughterClientData = {
                        ...clientData,
                        cvr: company.cvr,
                    };
                    await registerClientWithData(daughterClientData);
                }
            }
    
            if (selectedOwnerCompanies?.length > 0) {
                for (const company of selectedOwnerCompanies) {
                    const ownerClientData = {
                        ...clientData,
                        cvr: company.cvr,
                    };
                    await registerClientWithData(ownerClientData);
                }
            }
    
            await registerClientWithData({...clientData, selectedRole});
    
            await refreshTokens();
            await this.setFetching(false);
            onClientCreated?.();
            toast.success('Klienten blev oprettet!');
            this.close();
        } catch (e) {
            let error;
            let errorColor;
            if (e.status === 409) {
                error = 'Klienten er allerede oprettet';
                errorColor = 'blue';
            } else {
                error = 'Der opstod en ukendt fejl ved oprettelsen af klienten';
                errorColor = 'red';
            }

            this.setState({
                error,
                errorColor,
                fetching: false,
            });
        }
    };

    onEnterKeyPressed = e => {
        if (e.keyCode !== 13) {
            return;
        }

        if (this.isRegisterDisabled()) {
            return;
        }

        return this.onFormSubmit();
    };

    renderFormValues = () => {
        const { isPrivate } = this.props;
        if (isPrivate) {
            return this.renderFormValuesPrivate();
        }
        return this.renderFormValuesBusiness();
    };

    renderFormValuesPrivate = () => {
        return <>
            <Form.Field>
                <label>Fornavn</label>
                <Input
                    autoFocus
                    fluid
                    onChange={(_, { value }) => this.setState({ firstname: value })}
                />
            </Form.Field>
            <Form.Field>
                <label>Efternavn</label>
                <Input
                    fluid
                    onChange={(_, { value }) => this.setState({ lastname: value })} 
                />
            </Form.Field>
        </>;
    };

    renderFormValuesBusiness = () => {
        const { rapportLanguage } = this.state;
        return <>
                <CVRSearch onChange={({ cvr, ownerShip, selectedDaughterCompanies, selectedOwnerCompanies }) => this.setState({ cvr, ownerShip, selectedDaughterCompanies, selectedOwnerCompanies })} showDropdown={true} />
                <RapportLanguagePicker
                title={'Rapportsprog'}
                placeholder='Rapportsprog'
                defaultLanguage={rapportLanguage}
                onChange={(_, data) => { this.setState({ rapportLanguage: data.value }) }}
            ></RapportLanguagePicker>
        </>;
    };

    renderErrorMessage = () => {
        const { error,  errorColor } = this.state;

        return error && (
            <Message color={errorColor}>
                <Icon name='info circle' /> {error}
            </Message>
        );
    }

    isRegisterDisabled = () => {
        const { isPrivate } = this.props;
        if (isPrivate) {
            const fname = this.state.firstname;
            const lname = this.state.lastname;
            return !fname || !lname;
        }
        return !this.state.cvr;
    };

    renderModalHeader() {
        return <Header>
            <Icon.Group>
                <Icon name={this.props.icon} />
                <Icon name='plus' corner='top right' />
            </Icon.Group>
            &nbsp;&nbsp;
            {this.props.header}
        </Header>;
    }

    render () {
        const { fetching } = this.state;

        return (
            <Modal
                open
                onClose={this.close}
                closeIcon
            >
                {this.renderModalHeader()}
                <Modal.Content>
                    <Form
                        onKeyDown={this.onEnterKeyPressed}
                        children={this.renderFormValues()}
                    />
                    {this.renderErrorMessage()}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={this.close} disabled={fetching}>
                        <Icon name='remove'/> {i18n.RegisterForm.cancelButton}
                    </Button>
                    <Button
                        primary
                        onClick={this.onFormSubmit}
                        disabled={this.isRegisterDisabled() || fetching}
                        loading={fetching}
                    >
                        <Icon name='checkmark' /> {i18n.RegisterForm.registerButton}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default ModalContent;