import React                                   from 'react';
import { withRouter }                          from 'react-router-dom';
import get                                     from 'lodash.get';
import compose                                 from 'lodash.flowright';
import { toast }                               from 'react-toastify';
import { Icon, Loader, Popup }                 from 'semantic-ui-react';
import ProductPage                             from './ProductPage';
import BreadcrumbBar                           from './components/BreadcrumbBar';
import YouTubeSubheader                        from './components/YouTubeSubheader';
import NavigationWidget                        from './components/NavigationWidget';
import ResetButton                             from './components/ResetButton';
import FlowUpdateButton                        from './components/FlowUpdateButton';
import TeaserMessage                           from './components/TeaserMessage';
import LoadIndicator                           from 'design/atoms/LoadIndicator';
import Form                                    from 'design/atoms/Form';
import { resetSignflow }                       from 'design/molecules/VismaSignature/FlowStatus';
import { userStatusesEnum }                    from 'design/molecules/AppLauncher/userStatuses';
import { Media, withBreakpoints }              from 'design/atoms/Media';
import PageHeader                              from 'design/atoms/PageHeader';
import HintSection                             from 'design/molecules/HintSection/HintSection';
import ProductPageWrapper                      from 'design/molecules/ProductPage';
import LockStatus                              from 'design/pages/Product/components/LockStatus';
import withUserData                            from 'util/withUserData';
import {
    STATUS_FACT_ID,
    FILES_FACT_ID
}                                              from 'util/FactMapUtil';
import { isAdmin, hasRole, REVISOR }           from 'util/userMethods';
import { scrollToElement }                     from 'util/scrollTo';
import { publish }                             from 'util/pubsub';
import { cssAggregateClasses }                 from 'util/Utility';
import { getProductByID }                      from 'util/getProducts';
import {
    getSectionFields,
    getFactMap,
    printSection,
    runAction,
    updateManyFacts,
    getProductPrimoModelData,
} from 'http/productEngine';
import HintSectionAnalyser from 'design/molecules/HintSection/util/HintSectionAnalyser';
import { HINT_SECTION } from 'flags';
import { forceUpdateEsignatur } from 'design/molecules/Esignatur/FlowStatus';
import { TypeDocGen } from './FieldTypes/Fact';
import style from './ProductWrapper.module.scss';
import ColoredText from 'design/atoms/ColoredText';
import { Timer } from 'easytimer.js';

const GROUP_OPEN = 'group-open';
const GROUP_CLOSE = 'group-close';
const TYPES_WITH_SUBFIELDS = ['group', 'freebie-group'];

class ProductWrapper extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            loading: true,
            updating: false,
            factsBeingUpdated: new Set(),
            validationResult: {},
            executionResults: {},
        };

        this.currentFactBatchPromise = null;
        this.factsToUpdateBatch = [];
        this.hintSectionRef = React.createRef();
    }

    getInitialPage = sections => {
        if (this.props.page === 'index') {
            const { search } = this.props.location;
            const page = sections[0].slug;
            this.props.history.replace(`${this.props.pagePrefix}/${page}${search}`);
            return page;
        } else if (this.props.page === 'last') {
            const { search } = this.props.location;
            const page = sections[sections.length - 1].slug;
            this.props.history.replace(`${this.props.pagePrefix}/${page}${search}`);
            return page;
        }

        return this.props.page;
    };

    componentDidMount = async () => {
        await this.loadProductData();
    };

    componentDidUpdate = (oldProps) => {
        if (oldProps.page !== this.props.page) {
            this.setActiveSection(this.props.page);
        }
    };

    getSectionFields = async slugId => {
        const { product, label, userData } = this.props;
        const allFields = await getSectionFields(product, label, slugId);

        const removeIrrelevantFields = fields => {
            return fields.filter(field => {
                // check if field is relevant according to flagged roles on field
                const roles = field?.roles;
                if (roles?.length > 0) {
                    const rolesShouldExclude = field?.rolesShouldExclude;

                    if (rolesShouldExclude) {
                        if (roles.some(role => hasRole(userData, role))) {
                            return false;
                        }
                    } else {
                        if (!roles.some(role => hasRole(userData, role))) {
                            return false;
                        }
                    }
                }

                // handle potential sub fields
                if (TYPES_WITH_SUBFIELDS.includes(field?.type)) {
                    field.field = removeIrrelevantFields(field.field);
                }

                return true;
            });
        };


        return removeIrrelevantFields(allFields);
    };

    getCurrentSection = () => {
        const { currentSectionIndex, data } = this.state;
        return data?.visibleSections[currentSectionIndex];
    };

    getCurrentSectionSlug = () => {
        return this.getCurrentSection()?.slug;
    };

    getFactMap = () => {
        return getFactMap(this.props.product, this.props.label);
    };

    getSaasProduct = () => {
        const { productMetadata } = this.props;

        if (productMetadata.stripeID) {
            return getProductByID(productMetadata.stripeID);
        }

        return Promise.resolve(null);
    };

    getPrimoModelData = () => {
        if (this.state.primoModelData) {
            return this.state.primoModelData;
        }

        const { product, label } = this.props;
        return getProductPrimoModelData(product, label);
    };

    changeSection = async (index, toAdd) => {
        const newIndex = index + toAdd;

        if (isNaN(newIndex)) {
            return;
        }

        const { slug } = this.state.data.visibleSections[newIndex];
        this.pushHistory(`${this.props.pagePrefix}/${slug}`);
    };

    setActiveSection = async slug => {
        if (this.state.loading) {
            return;
        }

        const fields = await this.getSectionFields(slug);

        const idx = this.state.data.visibleSections.findIndex(s => s.slug === slug);

        const afterLoadHook = () => {
            // providing a field ID as hash will cause
            // automatic scroll to that element
            const hash = window.location.hash.substring(1);
            const fieldID = decodeURIComponent(hash);

            if (fieldID) {
                // wait a short amount of time after page load
                // before performing the scroll animation
                setTimeout(() => {
                    // open group(s) containing the field
                    this.openGroupsWithFields([fieldID]);

                    // scroll to field
                    scrollToElement(fieldID);
                }, 100);
                return;
            }

            // else, just scroll to top of document
            window.scrollTo(0, 0);
        };

        this.setState({
            currentSectionIndex: idx,
            data: {
                ...this.state.data,
                fields,
            },
        }, afterLoadHook);
    };

    nextSection = async () => {
        const { currentSectionIndex, updating } = this.state;
        const { visibleSections } = this.state.data;
        const { landingLocation } = this.props;

        // can't change section while updating
        if (updating) {
            return;
        }

        // has next?
        if (currentSectionIndex < visibleSections.length - 1) {
            return this.changeSection(currentSectionIndex, 1);
        }

        // go to product home
        this.pushHistory(landingLocation);
    };

    prevSection = () => {
        const { currentSectionIndex } = this.state;

        // has previous?
        if (currentSectionIndex > 0) {
            return this.changeSection(currentSectionIndex, -1);
        }

        // go to product home
        this.pushHistory(this.props.landingLocation);
    };

    findSectionIndex = (sections, slug) => {
        for (let i = 0; i < sections.length; i++) {
            if (sections[i].slug === slug) {
                return i;
            }
        }
        return -1;
    };

    pushHistory = url => this.props.history.push(`${url}${window.location.search}`);

    loadProductData = async () => {
        const { productMetadata } = this.props;

        try {
            const [factMapResp, primoModelData, saasProduct] = await Promise.all([
                this.getFactMap(),
                this.getPrimoModelData(),
                this.getSaasProduct(),
            ]);

            const { matchingPeriod, lastPeriod } = primoModelData.periodPair;
            const { factMap } = factMapResp;
            const { sections } = productMetadata;
            const visibleSections = this.visibleSections(sections, factMap);
            const page = this.getInitialPage(visibleSections);
            const fields = await this.getSectionFields(page);

            const productData = {
                primoModelData,
                currentSectionIndex: this.findSectionIndex(visibleSections, page),
                currentPeriod: matchingPeriod,
                lastPeriod: lastPeriod,
                saasProduct,
                loading: false,
                data: {
                    sections,
                    fields,
                    productData: factMap,
                    visibleSections,
                }
            };

            this.setState(productData);
        } catch (err) {
            this.setState({
                error: err.msg,
            });
        }
    };

    visibleSections = (sections, productData) => {
        const { userData } = this.props;

        const checkRole = role => {
            if (role === REVISOR && userData.showAccountantFeatures) {
                return true;
            }

            return hasRole(userData, role);
        };

        const toReturn = sections.filter(section => {
            if (section.isHidden) {
                return false;
            }
            
            // check visibility rule results
            if (section.hasVisibilityReferences) {
                if (!this.isVisible(section.visibilityReferences, productData.values)) {
                    return false;
                }
            }

            // check role requirments
            if (section.requiresRoles) {
                if (!section.roles.some(role => checkRole(role))) {
                    return false;
                }
            }

            return true;
        });
        return toReturn;
    };


    runUpdateFactBatch = async resolve => {
        const factsToUpdate = this.factsToUpdateBatch;
        this.factsToUpdateBatch = [];

        this.setState({
            factsBeingUpdated: new Set(factsToUpdate.map(fact => fact.id)),
        });

        const { product, label } = this.props;
        
        const updatedFactMap = await updateManyFacts(product, label, factsToUpdate);

        this.setState({
            data: {
                ...this.state.data,
                productData: updatedFactMap,
                visibleSections: this.visibleSections(this.state.data.sections, updatedFactMap),
            },
        });

        if (this.factsToUpdateBatch.length > 0) {
            // keep running, if more updates has been batched since last request
            this.runUpdateFactBatch(resolve);
        } else {
            resolve({
                factMap: updatedFactMap,
                lastUpdatedFact: factsToUpdate[factsToUpdate.length - 1],
            });
        }
    };

    batchFactUpdate = async (factUpdate) => {
        this.factsToUpdateBatch.push(factUpdate);

        if (this.currentFactBatchPromise) {
            return this.currentFactBatchPromise;
        }

        this.setState({ updating: true });

        // will resolve when all fact updates in the queue has been processed
        this.currentFactBatchPromise = new Promise(resolve => {
            this.runUpdateFactBatch(resolve);
        });

        const { factMap, lastUpdatedFact } = await this.currentFactBatchPromise;

        const field = this.findFieldByNodeId(lastUpdatedFact.id);
        
        const validationResult = {};
        if (factMap.validationResult?.isValid === false) {
            validationResult[field.field.id] = factMap.validationResult.advisoryMessage;
        }

        this.setState({
            validationResult,
            updating: false,
            factsBeingUpdated: new Set(),
            executionErrors: factMap.executionErrors,
        });

        this.currentFactBatchPromise = null;
    };

    fieldDataChanged = (id, value, updateContext = {}) => {
        const section = this.getCurrentSectionSlug();
        const field = this.findFieldByNodeId(id);

        return this.batchFactUpdate({
            id,
            value,
            updateContext,
            section,
            fieldID: field?.fieldId,
        });
    };

    runFieldAction = async (field, updateContext) => {
        const { product, label } = this.props;

        // signal update
        this.setState({ updating: true, factsBeingUpdated: new Set([field?.id]) });

        // do run field action
        await runAction(product, label, field?.supplier, updateContext);

        // reload fact data
        await this.loadProductData();

        this.setState({
            updating: false,
            factsBeingUpdated: new Set(),
        });
    };

    extractValue = val => {
        try {
            return Object.values(val.value)[0];
        } catch (e) {
            return undefined;
        }
    };

    isVisible = (visibilityReferences, values) => {
        if (!visibilityReferences) {
            return true;
        }
        for (let i = 0; i < visibilityReferences.length; i++) {
            const current = visibilityReferences[i];
            if (!this.extractValue(values[current])) {
                return false;
            }
        }
        return true;
    };

    findFieldByNodeId = nodeID => {
        return this._findFieldByNodeID(this.state.data.fields, nodeID);
    };

    _findFieldByNodeID = (fields, nodeID) => {
        for (let f of fields) {
            const { field, type } = f;
            if (TYPES_WITH_SUBFIELDS.includes(type)) {
                const found = this._findFieldByNodeID(field, nodeID);
                if (found) {
                    return found;
                }
                continue;
            }

            if (field.type === 'Question' && field.supply === nodeID) {
                return f;
            }
            
            if (field.id === nodeID) {
                return f;
            }
        }
        return null;
    };

    getReferenceGroups = reference => {
        if (!reference) {
            return [];
        }

        const section = this.getCurrentSection();
        const groupStack = [];
        for (let field of section.fields) {
            if (field.reference === reference || field.id === reference) {
                return groupStack;
            }

            if (field.type === GROUP_OPEN) {
                groupStack.push(field.id);
            } else if (field.type === GROUP_CLOSE) {
                groupStack.pop();
            }
        }
        return groupStack;
    };

    openGroupsWithFields = refs => {
        const groupsToOpen = new Set();
        refs.forEach(ref => {
            this.getReferenceGroups(ref).forEach(group => groupsToOpen.add(group));
        });
        
        for (let groupToOpen of groupsToOpen) {
            publish(groupToOpen, true);
        }
    };

    hasPaid = () => {
        return this.props.hasPayed;
    };

    isTeaser = () => {
        if (!this.state.saasProduct?.hasTeaserMode) {
            return false;
        }

        if (typeof this.props.isTeaser === 'boolean') {
            return this.props.isTeaser;
        }

        return this.state.currentPeriod?.isTeaser || false;
    };

    paywallActive = () => {
        const currentSection = this.getCurrentSection();
        const hasPayed = this.hasPaid();

        return currentSection.requiresPayment && !hasPayed;
    };

    teaserWallActive = () => {
        const currentSection = this.getCurrentSection();
        return currentSection.requiresPayment && this.isTeaser();
    };

    // find the Status fact in this.state.data.productData.values (by ID)
    getStatus = () => {
        const path = `productData.values.${STATUS_FACT_ID}.value.string`
        return get(this.state.data, path);
    };

    // find the doc-gen fact in this.state.data.productData.values (by tag)
    getDocGenFact = () => {
        try {
            const productDocGenFact = Object.values(this.props.productMetadata.facts).find(fact => {
                return fact.dataType === TypeDocGen;
            });

            if (!productDocGenFact) {
                return null;
            }

            const productDataValues = this.state.data?.productData?.values || {};

            return productDataValues[productDocGenFact.id];
        } catch (err) {
            console.error("Error: Find DocGen ID:", err);    
        }
        return null;
    };

    isLocked = () => {
        // check lock status from props
        const { isLocked } = this.props;
        if (isLocked) {
            return true;
        }

        // check lock status from static fact
        const { productData } = this.state.data;
        const lock = productData?.values?.LOCK;

        if (!lock) {
            return false;
        }
        return lock.value.isLocked && !lock.value.unlockedSections.includes(this.props.page);
    };

    doUnlockSignflow = () => {
        return this.fieldDataChanged(STATUS_FACT_ID, { string: userStatusesEnum.STARTED }, {
            shouldClearSignflow: true,
        });
    };

    unlock = () => {
        this.doUnlockSignflow();
    };

    // LockStatus handler (klik på "Lås op") - Annuller signflow
    unlockAndResetSignflow = async () => {
        await this.doUnlockSignflow();

        let docGenFact = this.getDocGenFact()
        if (!!docGenFact?.value?.doc_gen_data?.signFlow && docGenFact?.value?.doc_gen_data?.esignaturSignFlow === "") {
            resetSignflow(this.getDocGenFact(), this.fieldDataChanged)
        }
    };

    doUpdateSignflow = async (docGenFact) => {
        return await forceUpdateEsignatur(docGenFact);
    };

    updateFlow = (docGenFact) => {
        return this.doUpdateSignflow(docGenFact);
    };

    getPaymentLink = () => {
        return this.props.paymentURLWithRedirect;
    };

    getFiles = () => {
        const filesFact = get(this.state.data?.productData?.values, FILES_FACT_ID);
        return filesFact?.value || {};
    };

    onFilesChange = (files, updateContext = {}) => {
        return this.fieldDataChanged(FILES_FACT_ID, files, updateContext);
    };

    getPrintHandler = () => {
        const { loading } = this.state;
        if (loading) {
            return null;
        }
        
        const currentSection = this.getCurrentSection();
        if (!currentSection) {
            return null;
        }

        if (!currentSection.printable) {
            return null;
        }

        const requiresPayment = currentSection.requiresPayment;
        const hasPaid = this.hasPaid();
        if (requiresPayment && !hasPaid) {
            return null;
        }

        return async () => {
            try {
                this.setState({ updating: true });
                await printSection(this.props.product, this.props.label, currentSection.slug);
                toast.success('PDF-dokument genereret');
            } catch {
                toast.error('Kunne ikke printe siden');
            } finally {
                this.setState({ updating: false });
            }
        };
    };

    onProductElementInteraction = productElementInteraction => {
        return this.hintSectionRef.current?.onProductElementInteraction(productElementInteraction);
    };

    renderTeaserMessage = () => {
        if (!this.isTeaser()) {
            return null;
        }

        if (!this.state.currentPeriod) {
            return null;
        }

        const { year, month, day } = this.state.currentPeriod.end;
        const oneDayEpoch = 1000 * 60 * 60 * 24;
        const endDatePlusOne = new Date(Date.UTC(year, month, day) + oneDayEpoch);

        return <TeaserMessage
            taxYearStartDate={endDatePlusOne}
            taxYear={this.props.year}
        />;
    };

    renderResetButton = () => {
        const { userData } = this.props;
        if (!isAdmin(userData)) {
            return;
        }
        const statusValues = Object.values(userStatusesEnum);
        const status = this.getStatus();
        const statusIdx = statusValues.indexOf(status);
        const minIdx = statusValues.indexOf(userStatusesEnum.SIGNATURE_FLOW);
        if (statusIdx >= minIdx || status === userStatusesEnum.DONE) {
            return <ResetButton onReset={this.unlock} />;
        }
    };

    renderFlowUpdateButton = () => {
        const { userData } = this.props;
        if (!isAdmin(userData)) {
            return
        }
        const docGenFact = this.getDocGenFact()
        if (docGenFact?.value?.doc_gen_data?.signedPdf !== "") {
            return
        }
        const statusValues = Object.values(userStatusesEnum);
        const status = this.getStatus();
        const statusIdx = statusValues.indexOf(status)
        const minIdx = statusValues.indexOf(userStatusesEnum.SIGNATURE_FLOW); 
        if (statusIdx >= minIdx || status === userStatusesEnum.DONE) {
            return <FlowUpdateButton onUpdate={() => this.updateFlow(docGenFact)} />;
        }
    }

    renderLockSection = () => {
        if (!this.isLocked()) {
            return null;
        }
        return (
            <LockStatus status={this.getStatus()} onUnlock={this.unlockAndResetSignflow}/>
        );
    };

    renderYouTubeSubheader = youtubeVideo => {
        if (!youtubeVideo) {
            return;
        }

        const currentSection = this.getCurrentSectionSlug();

        return <YouTubeSubheader
            youtubeVideo={youtubeVideo}
            iconKey={currentSection}
        />;
    };

    renderAdminMenu = () => {
        if (!isAdmin(this.props.userData)) {
            return null;
        }

        const menuItems = [
            this.renderResetButton(),
            this.renderFlowUpdateButton(),
        ].filter(item => !!item);

        if (menuItems.length === 0) {
            return null;
        }

        return (
            <Popup
                basic
                on='click'
                position='bottom center'
                trigger={<Icon name='cog' link title='Adminindstillinger' />}
                content={
                    <>
                        <ColoredText bold content='Adminindstillinger' /><br />
                        {menuItems}
                    </>
                }
            />
        );
    };

    renderContent = productPageStyle => {
        const { loading, error } = this.state;
        if (error) {
            return <Form header='Der opstod en fejl' />;
        }

        if (loading) {
            return <Loader inline='centered' active size='huge' />
        }

        const { currentSectionIndex, updating, validationResult, factsBeingUpdated, primoModelData, executionErrors } = this.state;
        const { visibleSections, fields, productData } = this.state.data;
        const currentSection = visibleSections[currentSectionIndex];
        const { title, slug, dataIsCopyable } = currentSection;
        const hasNext = currentSectionIndex < visibleSections.length - 1;
        const isLocked = this.isLocked();
        const hasPayed = this.hasPaid();
        const paymentLink = this.getPaymentLink();
        const paywall = this.paywallActive();
        const teaserWall = this.teaserWallActive();
        const youtubeVideo = this.renderYouTubeSubheader(currentSection.youtubeVideo);

        const productPageContent = (
            <div>
                <Form header={title} icon={youtubeVideo}>
                    {this.renderLockSection()}
                    <ProductPage
                        key={slug}
                        fields={fields}
                        productMetadata={this.props.productMetadata}
                        productData={productData}
                        executionErrors={executionErrors}
                        primoModelData={primoModelData}
                        fieldDataChanged={this.fieldDataChanged}
                        onFilesChange={this.onFilesChange}
                        reloadProductData={this.loadProductData}
                        runFieldAction={this.runFieldAction}
                        unlockAndResetSignflow={this.unlockAndResetSignflow}
                        title={title}
                        slug={slug}
                        location={this.props.location}
                        taxYear={this.props.year}
                        pagePrefix={this.props.pagePrefix}
                        page={this.props.page}
                        product={this.props.product}
                        productLabel={this.props.label}
                        updating={updating}
                        validationResult={validationResult}
                        extractValue={this.extractValue}
                        isVisible={this.isVisible}
                        isTeaser={this.isTeaser()}
                        isLocked={isLocked}
                        files={this.getFiles()}
                        productStatus={this.getStatus()}
                        hasPayed={hasPayed}
                        paywall={paywall}
                        teaserWall={teaserWall}
                        paymentURL={paymentLink}
                        factsBeingUpdated={factsBeingUpdated}
                        dataIsCopyable={dataIsCopyable}
                        currentPeriod={this.state.currentPeriod}
                        lastPeriod={this.state.lastPeriod}
                        next={this.nextSection}

                        onProductElementInteraction={this.onProductElementInteraction}
                    />            
                </Form>
                <NavigationWidget
                    next={this.nextSection}
                    prev={this.prevSection}
                    hasNext={hasNext}
                    demo={!hasPayed}
                    updating={updating}
                    onPrint={this.getPrintHandler()}
                />
                <LoadIndicator loading={updating} windup={200} />
            </div>
        );

        // -------------------------

        let content = null;
        const {
            productMetadata,
        } = this.props;

        if(HINT_SECTION()) {
            content = (
                <>
                    <Media gte='computer'>
                        <HintSection
                            productMetadata={productMetadata}
                            currentSectionSlug={slug}
                            ref={this.hintSectionRef}
                            productPageStyle={productPageStyle}
                            timer={new Timer()}

                            // Properties needed for the Checklist
                            hasPaid={hasPayed}
                            factsBeingUpdated={factsBeingUpdated}
                            productData={productData}
                            sectionFieldWrappers={fields}
                        >
                            {productPageContent}
                        </HintSection>
                    </Media>
                    <Media lt='computer'>
                        {productPageContent}
                    </Media>
                </>
            );
        } else {
            content = productPageContent;
        }

        return (
            <div id='product-wrapper-content'>
                {content}
            </div>
        );
    };

    renderStepper = () => {
        const { loading, error } = this.state;

        if (loading || error) {
            return null;
        }

        return (
            <BreadcrumbBar
                changeSection={this.changeSection}
                highlightIndex={this.state.currentSectionIndex}
                sections={this.state.data.visibleSections}
                hasPayed={this.hasPaid()}
            />
        );  
    };

    renderSubHeader = () => {
        const { title, icon, link, rightContent } = this.props.subHeader;

        const content = (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5em' }}>
                {<div>{title}</div>}
                {this.renderAdminMenu()}
                {this.renderTeaserMessage()}
            </div>
        );

        return (
            <PageHeader
                icon={icon}
                content={content}
                link={link}
                rightContent={rightContent}
            />
        );
    };

    render = () => {
        const { breakpoints } = this.props;
        const {
            data
        } = this.state;
        
        const currentSectionSlug = this.getCurrentSectionSlug();
        if(!currentSectionSlug) {
            return (
                <ProductPageWrapper subHeader={this.renderSubHeader()}>
                    <Loader inline='centered' active size='huge' />
                </ProductPageWrapper>
            );
        }
        
        const styleBasedOnPage = (section) => {
            let productPageStyle = {};  
            if (section === 'rawerp') {
                productPageStyle = {
                    paddingTop: '1em',
                    width: '1400px',
                };
            } else {
                productPageStyle = {
                    paddingTop: '1em',
                };
            }

            return productPageStyle;
        };

        const className = cssAggregateClasses([
            {
                _class: 'product-page-wrapper_hint-section',
                predicate: () => {
                    if(!HINT_SECTION()) {
                        return false;
                    }

                    const { currentSectionIndex } = this.state;
                    const { visibleSections } = this.state.data;
                    const currentSection = visibleSections[currentSectionIndex];
                    const hintSectionAnalysis = HintSectionAnalyser.analyseHintSection(currentSection, this.state.data.fields);
                    const sectionContainsHints = hintSectionAnalysis.containsSectionHints();
                    const fieldsContainHints = hintSectionAnalysis.containsFieldHints();
                    return sectionContainsHints || fieldsContainHints;
                }
            }
        ], style);

        // Style of product page that descendants (e.g. HintSection) need to know about. Contains CSS units.
        const productPageStyle = {
            paddingTop: '1em'
        };

        return (
            <ProductPageWrapper
                short
                subHeader={
                    <>
                        {this.renderSubHeader()}
                        {this.renderStepper()}
                    </>
                }
                children={this.renderContent(productPageStyle)}
                className={className}
                style={styleBasedOnPage(currentSectionSlug)}            
                backgroundColor={breakpoints.select({
                    'eq mobile': 'white',
                })}
            />
        );
    };
}

export default compose(
    withBreakpoints,
    withUserData,
    withRouter,
)(ProductWrapper);
