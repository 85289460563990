import { deleteResource, getResource, postResource, HTTPClient, patchResource } from 'network/fetch';
import { refreshTokens } from 'network/fetch/refreshTokens';
import { makeQueryParams } from 'util/QueryParams';

const loginsClient = new HTTPClient({
    prefix: 'logins',
});

const entityClient = new HTTPClient({
    prefix: 'reporting-entities',
});

const currentEntityClient = new HTTPClient({
    prefix: 'reporting-entities/current',
});

const prefix = 'accounts';

export function getLoggedInUserData() {
    return getResource(prefix);
}

export function patchLoggedInUser(updateMap = {}) {
    return patchResource(prefix, updateMap);
}

export function registerClient (clientData) {
    return postResource('register/client', clientData);
}

export function registerSubRole (roleData) {
    return postResource('register/subrole', roleData);
}

export function getAccountsFiltered (filter, limit = 20) {
    const qparams = makeQueryParams({ filter, limit });
    return getResource(`${prefix}/paginated${qparams}`);
}

export async function deleteAccount () {
    return deleteResource(prefix);
}

export function countErpUsers (erp, params = {}) {
    const query = makeQueryParams(params);
    return getResource(`${prefix}/erp/${erp}/${query}`);
}

export async function updateLogo (logoFileID) {
    await patchLoggedInUser({
        logo: logoFileID,
    });
    await refreshTokens();
}

export function deleteLogo () {
    return updateLogo('');
}

export function resendActivationEmail () {
    return loginsClient.postResource('verification/resend');
}

export function verifyEmail(code) {
    return loginsClient.postResource(`verification/verify/${code}`);
}

export function unsubscribeNewsletter(code) {
    return postResource(`unsubscribe/verify/${code}`);
}

export function sendResetRequest (email) {
    return postResource('reset/request', { email });
}

export async function getProductStatuses(product) {
    return getResource('app-engine/statuses?product=' + product);
}

export function getReportingEntities() {
    return entityClient.getResource('/');
}

export function createReportingEntity({ cvr, firstname, lastname }) {
    return entityClient.postResource('/', {
        cvr,
        firstname,
        lastname,
    });
}

export function createOwnerShipEntity(loginEmail, companyInfo ) {
    return entityClient.postResource('/ownerShip', {
        loginEmail,
        companyInfo,
    });
}

export function getEntityLogins() {
    return currentEntityClient.getResource('/logins');
}

export async function getLoginEmailByLoginID(id) {
    return currentEntityClient.getResource(`/logins/${id}/email`);
};

export function deleteEntityLogin(id) {
    return currentEntityClient.deleteResource(`/logins/${id}`);
}

export function patchEntityLoginRole(id, newRole) {
    return currentEntityClient.patchResource(`/logins/${id}/role`, { newRole });
}

export function getEntityRoles() {
    return currentEntityClient.getResource('/roles');
}

export function getEntityInvitations() {
    return currentEntityClient.getResource('/invitations');
}

export function createEntityInvitation({ emailToInvite, desiredRole }) {
    return currentEntityClient.postResource('/invitations', {
        emailToInvite,
        desiredRole,
    });
}

export function getClient(clientID) {
    return currentEntityClient.getResource(`/clients/${clientID}`);
}

export function patchClient(clientID, updator = {}) {
    return currentEntityClient.patchResource(`/clients/${clientID}`, updator);
}

export function deleteClient(clientID) {
    return currentEntityClient.deleteResource(`/clients/${clientID}`);
}

export function deleteEntitiyInvitation(token) {
    return currentEntityClient.deleteResource(`/invitations/${token}`);
}

export function acceptEntityInvitation(token) {
    return entityClient.postResource(`/invitations/${token}/accept`);
}

export function describeInvitation(token) {
    return getResource(`invitations/${token}`);
}

export function getCurrentLogin() {
    return loginsClient.getResource('/');
}

export function patchCurrentLogin(updateMap = {}) {
    return loginsClient.patchResource('/', updateMap);
}

export function deleteCurrentLogin() {
    return loginsClient.deleteResource('/');
}

export function requestPasswordReset(email) {
    return postResource('reset-password-requests', { email });
}

export function executeResetPassword(token, newPassword, newPasswordRepeat) {
    return postResource(`reset-password-requests/${token}/execute`, {
        newPassword,
        newPasswordRepeat,
    });
}

export function validateRegistrationLink(email, token) {
    return getResource(`gpt/gpt-exists?email=${encodeURIComponent(email)}&token=${token}`);
}

export function getArchivedAppEngineVariations(productID) {
    return getResource(`${prefix}/app-engine-archive/${productID}/variations`);
}

export function getArchivedAppEngineVariation(productID, variation, year) {
    const path = `${prefix}/app-engine-archive/${productID}/variations/${variation}${year ? `/${year}` : ''}`;
    return getResource(path);
}
