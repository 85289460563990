// PaymentAccountantRoleForm.js
import React from 'react';
import { Button, Card, Grid, Icon, Message  } from 'semantic-ui-react';

const PaymentAccountantRoleForm = ({ selectedRole, setSelectedRole, onNext }) => {
  return (
    <>

    <Grid columns={2} stackable>
      <Grid.Row>
        <Grid.Column>
          <Card
            fluid
            raised={selectedRole === 'rapport'}
            onClick={() => setSelectedRole('rapport')}
            style={{ 
                cursor: 'pointer',
                backgroundColor: '#e8f1fb',
                border: selectedRole === 'rapport' ? '3px solid #2185d0' : 'none',
                boxShadow: selectedRole === 'rapport' ? '0 0 10px #2185d0' : 'none'
            }}
          >
            <Card.Content>
              <Card.Header>Bogholder</Card.Header>
              <Card.Description>
                <ul>
                  <li>Årsrapport</li>
                  <li>Godkendelse</li>
                  <li>Indberetning</li>
                  <li>Afslutningsark</li>
                  <li>ML mapning</li>
                  <li>ESG rapport</li>
                </ul>
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>

        <Grid.Column>
          <Card
            fluid
            raised={selectedRole === 'rapportErklaering'}
            onClick={() => setSelectedRole('rapportErklaering')}
            style={{ 
                cursor: 'pointer', 
                backgroundColor: '#9cfa9b',
                border: selectedRole === 'rapportErklaering' ? '3px solid #21ba45' : 'none',
                boxShadow: selectedRole === 'rapportErklaering' ? '0 0 10px #21ba45' : 'none'
            }}
          >
            <Card.Content>
              <Card.Header>Revisor</Card.Header>
              <Card.Description>
                <ul>
                  <li>Planlægning</li>
                  <li>Udførsel</li>
                  <li>Konklusion</li>
                  <li>Dokumentation</li>
                  <li>FSR standard Erklæringer</li>
                  <li>Ledelsens regnskabserklæring</li>
                  <li>Avancerede funktioner</li>
                  <li>OpenAPI</li>
                </ul>
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Button
            primary
            fluid
            icon='arrow right'
            labelPosition='right'
            content='Gå videre'
            disabled={!selectedRole}
            onClick={onNext}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Message info>
    <Message.Header>Valg af rolle</Message.Header>
    Før du køber dine credits vil vi bede dig tage stilling til hvilken rolle du har og dermed hvilke funtionaliteter du har brug for.
    Vær opmærksom på at når først du har valgt din rolle, så vil du ikke kunne skifte den før du har opbrugt de credits du har købt.
    <p>
        <strong>Rapport:</strong> Bruges oftes af bogholdere eller hvis du udelukkende har
        behov for årsrapport og hurtig indberetning.
    </p>
    <p>
        <strong>Rapport &amp; Erklæring:</strong> Bedst når du har brug for den fulde
        proces med erklæringer, dokumentation og avancerede funktioner.
        Denne er typisk valgt af revisorere 
    </p>
    </Message>
    </>
  );
};

export default PaymentAccountantRoleForm;
