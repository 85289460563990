import React, { Component } from 'react';
import { Grid, Button, Flag, Header, Icon, Popup, Message, List, Label, Segment, Progress } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import withUserData from 'util/withUserData';
import { isVirksomhed, isSelskab, isErhverv, ownsTaxYear } from 'util/userMethods';
import { formatDate } from 'util/format/DateTime';
import { paymentRedirect } from 'util/browserHistory';
import { formatPrice } from 'util/format/Price';
import { isImpersonating } from 'util/impersonation';
import capitalize from 'util/capitalize';
import { moms } from 'util/moms';
import { translateStatus } from 'design/molecules/AppLauncher/userStatuses';
import { Media } from 'design/atoms/Media';
import ColoredText from 'design/atoms/ColoredText';
import Tooltip from 'design/atoms/Tooltip';
import productStatuses from 'design/molecules/AppLauncher/productStatuses';
import { getFlagCode } from 'lang/countries';
import addonProductOptions from './addonProductComponents';

import styles from '../../AppLauncher.module.scss';

const ONE_DAY_MILLIS = 1000 * 60 * 60 * 24;

function daysBetween(a, b) {
    return Math.floor((b - a) / ONE_DAY_MILLIS);
}

export const ButtonWithPriceTag = ({ onClick, price, vat, prefix, tooltip, icon = 'unlock', content = 'Få fuld adgang' }) => {
    const renderPriceTag = priceToShow => {
        // don't show price tags during impersonation
        if (isImpersonating()) {
            return null;
        }

        let price;
        let priceTooltip;
        if (vat) {
            price = moms(priceToShow);
            priceTooltip = 'Inklusiv moms';
        } else {
            price = priceToShow;
            priceTooltip = 'Ekslusiv moms';
        }

        const content = `${formatPrice(price)} kr.`;

        const VatTooltip = ({ children }) => {
            return <Popup
                trigger={children}
                content={priceTooltip}
                position='top center'
            />
        };

        const thePrefix = prefix && (
            <span>{prefix} </span>
        );

        return <>
            {/* Computer: price tag next to buy button */}
            <Media gte='computer'>
                <VatTooltip>
                    <Label tag content={<>{thePrefix}{content}</>} />
                </VatTooltip>
            </Media>

            {/* Less than computer: price tag pointing to buy button located above */}
            <Media lt='computer'>
                <br />
                <VatTooltip>
                    <Label
                        pointing='above'
                        content={
                            <span>
                                <Icon name='tag' />
                                {thePrefix}{content}
                            </span>
                        }
                    />
                </VatTooltip>
            </Media>
        </>;
    };


    const button = (
        <Button
            className={styles.taxYearButton}
            content={content}
            onClick={onClick}
            icon={icon}
            size='mini'
        />
    );
    
    const buttonWithTooltip = tooltip && (
        <Popup
            basic
            position='top center'
            trigger={button}
            content={tooltip}
        />
    );

    return (
        <>
            {buttonWithTooltip || button}
            {renderPriceTag(price)}
        </>
    );
};

class TaxYearNew extends Component {
    userOwnsTaxYear = (product, year) => {
        const { userData } = this.props;
        return ownsTaxYear(userData, product.id, year);
    };

    renderTeaserTooltip = () => {
        if (!this.props.product.hasTeaserMode) {
            return;
        }

        const { isTeaser, endDate } = this.props.year;
        if (!isTeaser) {
            return;
        }

        return (
            <Tooltip
                basic
                triggerOverwrite={<Label color='blue' content='Skatteår ikke afsluttet' icon='info circle' basic />}
                data={this.renderTeaserText(endDate)}
            />
        );
    };

    renderTeaserText = endDate => {
        const nextPeriodStart = new Date(new Date(endDate).getTime() + ONE_DAY_MILLIS);
        const daysTillNextPeriod = daysBetween(Date.now(), nextPeriodStart.getTime());

        let introText;
        if (daysTillNextPeriod >= 1 && daysTillNextPeriod <= 31) {
            const daysUnit = daysTillNextPeriod === 1 ? 'dag' : 'dage';
            introText = `Der er stadig ${daysTillNextPeriod} ${daysUnit} tilbage af skatteåret`;
        } else {
            introText = 'Dette skatteår er stadig i gang';
        }

        return <>
            <p>
                {introText}, og derfor kan du kun bruge værktøjet i en prøvetilstand.
                Det betyder, at du ikke kan underskrive årsrapporten endnu.
            </p>
            <p>
                Når dit skatteår er afsluttet den <b>{formatDate(nextPeriodStart)}</b>,
                åbner vi op for, at du kan bruge alle værktøjets funktioner
                og lave en fuld årsrapport.
            </p>
        </>
    };

    createStatusLabel = ({ displayText, tooltip }) => {
        if (!displayText) {
            console.error("Missing status. No label");
            return null;
        }

        const label = (
            <p className={styles.productProgress}>
                {displayText} {tooltip && <Icon name='question circle' />}
            </p>
        );

        if (tooltip) {
            return (
                <Popup
                    trigger={label}
                    content={tooltip}
                    position='top center'
                    inverted
                    size='tiny'
                />
            );
        }

        return label;
    };

    renderTaxYear = () => {
        const { product } = this.props;
        const { year, variations } = this.props.year;
        const paid = this.userOwnsTaxYear(product, year);
        const onPaymentClicked = () => paymentRedirect({
            product: product.id,
            taxYear: year,
            hash: year,
            clickRef: 'buy_tax_year_button',
        });

        let content;

        if (variations.length > 0) {
            content = variations.map(this.renderVariation);
        } else {
            content = (
                <Message
                    color='blue'
                    icon='info circle'
                    className={styles.teaserMessage}
                    content={`Skatteåret for ${year} er ikke klar, fordi Skat endnu ikke har offentliggjort det. Vi giver besked, når du kan beregne skatten for ${year}.`}
                />
            );
        }

        return (
            <Grid stackable>
                <Grid.Row>
                    {this.renderTaxYearHeader({
                        product: product,
                        year: year,
                        paid: paid,
                        headerLabel: this.renderTeaserTooltip(),
                        onClick: onPaymentClicked,
                    })}
                </Grid.Row>
                {content}
            </Grid>
        );
    };


    renderTaxYearHeader = ({ product, year, paid, onClick, headerLabel, title = 'Skatteår' }) => {
        const purchaseButton = !paid && (
            <ButtonWithPriceTag
                vat={product.vat}
                price={product.defaultPrice}
                prefix='Fra'
                onClick={onClick}
            />
        );

        return (
            <Grid.Column width={16}>
                <Media gte='computer'>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flex: 1 }}>
                            <Header as='h3'>
                                {title} {year} {headerLabel}
                            </Header>
                        </div>
                        <div>
                            {purchaseButton}
                        </div>
                    </div>
                </Media>

                <Media lt='computer'>
                    <div style={{ textAlign: 'center' }}>
                        {purchaseButton}
                    </div>
                </Media>
            </Grid.Column>
        );
    };

    
    renderProgressBar = (variation) => {
        const { product } = this.props;
        let progress = 0;
        let color;
        let taxYearStatus = translateStatus(variation.userStatus);

        if(product.id === "boligudlejning") {
            if(taxYearStatus.step === 1) {
                progress = 30;
                color = 'yellow';
            }
            if(variation.extraStatus === "taxSystemSelected") {
                progress = 80;
                color='yellow'
            }
            if (taxYearStatus.step === 5){
                progress = 100;
                color='green'
            }
            return(
                <>
                    <div style={{
                        display: taxYearStatus.step === 0 || variation.type === "Forskudsopgørelse" ? 'none' : 'flex',
                        justifyContent: 'center',
                        alignItems:'center',
                        width: '100%',
                        marginTop: '20px'
                    }}>
                        <Progress size='tiny' percent={progress} color={color} style={{width: '100%', margin:'0'}} /> 
                        {progress === 100 ? <Icon color='green' style={{marginLeft:'10px'}} circular inverted size='small' name='checkmark'/> : <p style={{marginLeft: '10px', fontSize:'0.9rem'}}>{progress}%</p>}
                    </div>
                </>
            )
        }
        else {
            if(taxYearStatus.step === 1) {
                progress = 30;
                color = 'yellow';
            }
            if(taxYearStatus.step === 2) {
                progress = 40;
                color = 'yellow';
            }
            if(taxYearStatus.step === 3) {
                progress = 60;
                color = 'yellow';
            }
            if(taxYearStatus.step === 4) {
                progress = 70;
                color = 'yellow';
            }

            if (taxYearStatus.step === 5){
                progress = 100;
                color='green'

            }
            return(
                <>
                    <div style={{
                        display: taxYearStatus.step === 0 ? 'none' : 'flex',
                        justifyContent: 'center',
                        alignItems:'center',
                        width: '100%',
                        marginTop: '20px'
                    }}>
                        <Progress size='tiny' percent={progress} color={color} style={{width: '100%', margin:'0'}} /> 
                        {progress === 100 ? <Icon color='green' style={{marginLeft:'10px'}} circular inverted size='small' name='checkmark'/> : <p style={{marginLeft: '10px', fontSize:'0.9rem'}}>{progress}%</p>}
                    </div>
                </>
            )        
        }
    }

    renderVariation = variation => {
        const {
            productID,
            productLink,
            type,
            year,
            status,
            startDate,
            endDate,
            primaryLanguage,
            highlighted,
            teaser,
            firstYear,
            lastYear,
            requiresPayment,
            isArchived,
        } = variation;
        const { hasBase, baseCompleted, userData } = this.props;
        const ownsVariation = ownsTaxYear(userData, productID, year.toString());


        // Status specific customization
        let statusMessage;
        if (isArchived) {
            statusMessage = this.createStatusLabel({
                displayText: 'Afsluttet',
                tooltip: `Skatteåret ${year} er afsluttet. Du kan se dine tal for skatteåret ${year} ved at klikke på knappen nedenunder.`,
            });
        } else if (status === productStatuses.FUTURE_TEASER) {
            statusMessage = this.createStatusLabel({
                displayText: 'Kommer senere',
            });
        } else if (teaser) {
            statusMessage = this.createStatusLabel({
                displayText: 'Skatteår ikke afsluttet endnu',
            });
        } else {
            statusMessage = this.createStatusLabel(translateStatus(variation.userStatus));
        }

        let isHighlighted;
        if (highlighted !== undefined) {
            isHighlighted = highlighted;
        } else {
            isHighlighted = status === productStatuses.HIGHLIGHTED;
        }

        // determine the style of the tax year card
        let style;
        if (teaser) {
            style = productStatuses.LOCKED;
        } else if (isHighlighted) {
            style = productStatuses.HIGHLIGHTED;
        } else if (status === productStatuses.LOCKED) {
            style = productStatuses.LOCKED;
        } else if (status === productStatuses.FUTURE_TEASER) {
            style = productStatuses.FUTURE_TEASER;
        } else {
            style = productStatuses.ACTIVE;
        }

        // format the button that opens the tax year
        let openButtonTxt;
        let openButtonDisabled = false;
        if (isArchived) {
            openButtonTxt = `Se ${type}`;
        } else if (status === productStatuses.FUTURE_TEASER) {
            openButtonDisabled = true;
            openButtonTxt = 'Åbn';
        } else if (teaser) {
            openButtonTxt = 'Kør i prøvetilstand';
        } else if (!ownsVariation) {
            openButtonTxt = 'Prøv gratis demo';
        } else {
            openButtonTxt = 'Åbn';
        }

        // Format period if provided
        let period;
        if (!!startDate && !!endDate) {
            period = <>Fra <b>{formatDate(startDate)}</b> til <b>{formatDate(endDate)}</b></>;
        }

        const getPeriodDescription = () => {
            if (variation.predefinedLabel) {
                return variation.predefinedLabel;
            }

            const affectedTaxYears = [];
            if (firstYear) {
                affectedTaxYears.push('første');
            }

            if (lastYear) {
                affectedTaxYears.push('sidste');
            }

            if (affectedTaxYears.length === 0) {
                return null;
            }

            let userType;
            switch (true) {
                case isVirksomhed(userData):
                    userType = 'virksomhedens ';
                    break;
                case isSelskab(userData):
                    userType = 'selskabets ';
                    break;
                default:
                    userType = '';
                    break;
            }

            return capitalize(`${userType}${affectedTaxYears.join(' og ')} skatteår`);
        };

        const taxYearLabel = getPeriodDescription();

        // prepare tax year action button
        const actionButton = (
            <Button
                fluid
                content={openButtonTxt}
                disabled={(hasBase && !baseCompleted) || openButtonDisabled}
                onClick={() => {
                    if (requiresPayment && !ownsVariation) {
                        return;
                    }
                    this.props.history.push(productLink);
                }}
                className={styles.productButton}
            />
        );

        let action;
        let cornerLabel;

        // show payment popup, if variation requires payment
        if (requiresPayment && !ownsVariation) {
            const options = [];

            // get all variations in tax year
            const variations = this.props.taxYears.find(ty => ty.year === year)?.variations || [];
            
            // attempt to find a free variation
            const freeVariation = variations.find(v => !v.requiresPayment);

            // add the free variation as an option (if found)
            if (freeVariation) {
                options.push({
                    icon: 'arrow alternate circle right', 
                    content: 'Prøv forskudsopgørelse',
                    description: 'Vil du prøve vores værktøj? Det må du gerne. Lær det at kende i demoversionen af forskudsopgørelsen.',
                    onClick: () => this.props.history.push(freeVariation.productLink),
                });
            }

            options.push({
                icon: 'unlock',
                content: `Få fuld adgang til skatteår ${year}`,
                description: `For at lave oplysningsskemaet skal du købe adgang til skatteåret ${year}.`,
                onClick: () => paymentRedirect({
                    product: productID,
                    taxYear: year,
                    hash: year,
                    clickRef: 'selvangivelse_paywall_popup',
                }),
            });

            const optionItems = [];

            for (let { icon, content, description, onClick } of options) {
                optionItems.push(
                    <List.Item
                        content={
                            <p key={`key-${icon}`}>
                                <span className={styles.choiceDescription}>
                                    {description}
                                </span>
                                <ColoredText 
                                    key={`text-${icon}`}
                                    link
                                    icon={icon}
                                    content={content}
                                    onClick={onClick}
                                    iconPosition='left'
                                />
                            </p>
                        }
                    />,
                );
            }

            action = (
                <Popup
                    on='click'
                    position='left center'
                    content={<List>{optionItems}</List>}
                    trigger={actionButton}
                />
            );

            cornerLabel = (
                <Label
                    className={styles.cornerLock}
                    icon='lock'
                    color='black'
                    corner
                />
            );
        } else {
            action = actionButton;
        }

        // Get current language of a given variation of a given user
        const rapportLanguage = primaryLanguage ? primaryLanguage : (userData.rapportLanguage || 'da-DK');
        const rapportLanguageFlag = isErhverv(userData) && userData.cvr ? <>&nbsp;<Flag name={getFlagCode(rapportLanguage)}/></> : null;

        return (
            <Grid.Row
                key={variation.productLink + variation.label}
                className={classNames(styles.productStatus, styles[style])}
                stackable
            >
                <Grid.Column
                    textAlign='left'
                    computer='11'
                    mobile='16'
                    tablet='16'
                >
                    <Header className={styles.header} >
                        <span>
                            {`${capitalize(type)} ${year}`}
                            {rapportLanguageFlag}
                        </span>
                        {this.renderProgressBar(variation)}
                        {period && <Header.Subheader>{period}</Header.Subheader>}
                        {taxYearLabel && <Header.Subheader className={styles.header}>{taxYearLabel}</Header.Subheader>}
                    </Header>
                </Grid.Column>
                <Grid.Column
                    textAlign='right'
                    computer='5'
                    mobile='16'
                    tablet='16'
                >
                    {statusMessage}
                    {action}
                </Grid.Column>
                {cornerLabel}
            </Grid.Row>
        );
    };

    renderAddonProducts = () => {
        if (isImpersonating()) {
            return null;
        }

        const { product, ownedAddonProducts } = this.props;

        const packageTiers = product.subscriptionPackageSet?.tiers || [];
        if (packageTiers.length === 0) {
            return null;
        }

        const { year } = this.props.year;

        const ownsTaxYear = this.userOwnsTaxYear(product, year);

        return packageTiers.map(tier => {
            if (!tier.addonProductID) {
                return null;
            }

            const OptionsComponent = addonProductOptions[tier.addonProductID];
            if (!OptionsComponent) {
                return null;
            }

            const addonProduct = this.props.addonProducts.find(product => {
                return product.id === tier.addonProductID;
            });

            const ownedAddonProduct = ownedAddonProducts.find(ownedProduct => {
                return (
                    ownedProduct.productID === tier.addonProductID &&
                    ownedProduct.data.taxYear === year.toString()
                );
            });

            const paymentLink = `/betaling/opgrader/${product.id}/${year}/${tier.id}`;

            const paymentButton = !ownedAddonProduct && ownsTaxYear && (
                <Link to={paymentLink}>
                    <ButtonWithPriceTag
                        vat={product.vat}
                        price={tier.price - product.defaultPrice}
                        content={`Køb ${addonProduct.name}`}
                        icon={tier.semanticIcon}
                    />
                </Link>
            );

            return (
                <OptionsComponent
                    product={addonProduct}
                    hasPaid={!!ownedAddonProduct}
                    paymentButton={paymentButton}
                    ownedAddonProduct={ownedAddonProduct}
                    onUpdate={() => this.props.reloadRelatedOwnedAddonProducts()}
                />
            );
        });
    };

    renderESGProduct = () => {
        const { esgService } = this.props;
        if (!esgService) {
            return null;
        }

        const { year } = this.props.year;

        const esgTaxYear = esgService.taxYears.find(taxYear => {
            return taxYear.year === year;
        });

        if (!esgTaxYear) {
            return null;
        }
        
        const paid = this.userOwnsTaxYear(esgService.product, year);
        const onPaymentClicked = () => paymentRedirect({
            product: esgService.product.id,
            taxYear: year,
            hash: year,
            clickRef: 'buy_tax_year_button',
        });
        
        const headerLabel = (
            <Tooltip
                basic
                triggerOverwrite={<Label color='blue' content='Nyhed!' icon='info circle' />}
                data={(
                    'Vi har gjort ESG-rapporteringen nem for små- og mellemstore virksomheder. ' +
                    'Det nye værktøj er baseret på VSME-standarden, ' +
                    'der følger CSRD og giver dig mulighed for at beregne drivhusgasudledninger (GHG) ' +
                    'og automatisk generere en æstetisk rapport.'
                )}
            />
        );

        const formattedEsgVariations = esgTaxYear.variations.map(variation => {
            return {
                ...variation,
                // ESG products are relevant outside of the users fiscal period
                // so the "teaser" status should always be false
                teaser: false,
            };
        });

        return (
            <Segment padded>
                <Grid stackable>
                    <Grid.Row>
                        {this.renderTaxYearHeader({
                            product: esgService.product,
                            year: year,
                            paid: paid,
                            onClick: onPaymentClicked,
                            headerLabel: headerLabel,
                            title: 'ESG-rapport',
                        })}
                    </Grid.Row>
                    {formattedEsgVariations.map(esgVariation => this.renderVariation(esgVariation))}
                </Grid>
            </Segment>
        );
    }

    render () {
        return (
            <>
                <Segment.Group>
                    <Segment padded>
                        {this.renderTaxYear()}
                    </Segment>
                    {this.renderAddonProducts()}
                </Segment.Group>
                {this.renderESGProduct()}
            </>
        );
    }
}

export default withUserData(TaxYearNew);
