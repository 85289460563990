import { moms } from 'util/moms';

export const doPriceCalculations = ({ orderLines, proactiveVat, discount }) => {
    // calculate subtotal from picked cart items
    const subtotal = orderLines.reduce((sum, { price }) => {
        return sum + price;
    }, 0);

    let discountAmount = 0;
    let discountDescription;
    
    if (discount?.active) {
        discountAmount = discount.purchaseAmountOff;
        discountDescription = discount.name;
    }

    const discountedPrice = Math.max(subtotal - discountAmount, 0);
    const vat = discountedPrice * 0.25;
    const total = discountedPrice + vat;

    return {
        subtotal: proactiveVat ? moms(subtotal) : subtotal,
        discountAmount: proactiveVat ? moms(discountAmount) : discountAmount,
        discountedPrice: proactiveVat ? moms(discountedPrice) : discountedPrice,
        discountDescription,
        vat,
        total,
    };
};