import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import {
	Grid,
	Header,
	Icon,
	Button,
	Segment,
	Message,
} from 'semantic-ui-react';

import i18n                         from 'i18n/pages/Register';
import { getParam, hasQueryParam }  from 'util/QueryParams'
import { PRIVAT, ERHVERV, AKADEMI, SEGMENT_AKADEMI } from 'util/userMethods';
import AuthHelper      	            from 'util/AuthHelper';
import googleTagManager	            from 'util/GoogleTagManager';
import ColoredText                  from 'design/atoms/ColoredText';
import { validateRegistrationLink }	from 'http/accounts';
import OwnerShipModal 				from '../../molecules/OwnerShipModal/OwnerShipModal.jsx';
import RegisterForm                 from './parts/RegisterForm';
import styles                       from './Register.module.scss';
import HeroForm from 'design/atoms/HeroForm';
import { JOINT_TAX } from 'flags';

const SegmentChoice = ({ content, icon, active, onClick }) => {
	const className = (
		active ?
		styles.activeLink :
		styles.regularLink
	);

	return (
		<Grid.Column
			textAlign='center'
			style={{
				cursor: 'pointer',
				backgroundColor: active ? 'white' : undefined,
				border: active ? '1px solid lightgray' : undefined,
				borderRadius: '4px',
				padding: '0.5em',
			}}
		>
			<h4
				onClick={onClick}
				className={className}
			>
				<Icon name={icon} circular size='large' />
				<br />
				<b>{content}</b>
			</h4>
		</Grid.Column>
	);
};

const SEGMENT = 'segment';

class Register extends React.Component {
	constructor (props) {
		super(props);
		this.state = this.getInitialState();
		this.segmentDescriptions = {
			[PRIVAT]: {
				title: 'Boligudlejer',
				icon: 'home',
			},
			[ERHVERV]: {
				title: 'Virksomhed',
				icon: 'factory',
			},
		};

		this.formProperties = [
			{
				key: 'firstname',
				segments: [PRIVAT],
				hideIfInvitation: true,
			},
			{
				key: 'lastname',
				segments: [PRIVAT],
				hideIfInvitation: true,
			},
			{
				key: 'cvr',
				segments: [ERHVERV],
				hideIfInvitation: true,
			},
			{
				key: 'className',
				segments: [AKADEMI],
				hideIfInvitation: true,
			},
			{
				key: 'email',
				segments: null,
				hideIfInvitation: false,
			},
			{
				key: 'phone',
				segments: [PRIVAT, ERHVERV],
				hideIfInvitation: true,
			},
			{
				key: 'password',
				segments: null,
				hideIfInvitation: false,
			},
			{
				key: 'repeatPassword',
				segments: null,
				hideIfInvitation: false,
			},
			{
				key: 'wantsNews',
				hideIfInvitation: getParam(SEGMENT) !== SEGMENT_AKADEMI,
			},
			{
				key: 'consent',
				segments: null,
				hideIfInvitation: false,
			},

		];
	}

	getInitialState = () => ({
		formValues: {},
		errors: {},
		correct: new Set(),
		isFetching: false,
		signupDone: false,
		invitationToken: null,
		gptToken: null,
		isPerformingIntegration: false,
		modalOpen: false,
	});

	resetState = () => {
		return new Promise(resolve => {
			this.setState(this.getInitialState(), resolve);
		});
	};

	static defaultProps = {
		...React.Component.defaultProps,
		userData: {},
	}

	componentDidMount = async () => {
		const invitationToken = getParam('invitationToken');
		this.setState({ invitationToken });

		const segment = getParam(SEGMENT);
		if (segment && !this.props.location.pathname.endsWith(segment)) {
			this.props.history.push({
				pathname: `/register/${segment}`,
				search: this.props.location.search,
			});
		}

		// Validate possible registration link, i.e. [email, token]
		const [ email, gptToken ] = ['email', 'token'].map(x => getParam(x));
		if(email && gptToken) {
			const { tokenExists } = await validateRegistrationLink(email, gptToken);
			if(!tokenExists) {
				this.goTo('/');
			} else {
				this.setState({ gptToken });
			}
		}
	};

	getRelevantFormProperties = () => {
		const userSegment = this.getUserSegment();
		const relevantProperties = this.formProperties.filter(({ hideIfInvitation, segments }) => {
			if (this.state.invitationToken && hideIfInvitation) {
				return false;
			}

			if (segments?.length > 0 && !segments.includes(userSegment)) {
				return false;
			}

			return true;
		}).map(prop => prop.key);

		return new Set(relevantProperties);
	};

	isSegmentSelectorActive = () => {
		// user was sent to register form w/ segment param
		if (hasQueryParam(SEGMENT)) {
			return false;
		}

		// user was invited to join a reporting entitiy (segment is predefined)
		if (this.state.invitationToken) {
			return false;
		}
		
		const userSegment = this.getUserSegment();
		if (userSegment === AKADEMI) {
			return false;
		}
		
		if (this.state.isPerformingIntegration) {
			return false;
		}

		return true;
	};


	handleRegister = async (data) => {
		this.setState({ isFetching: true });
		const userSegment = this.getUserSegment();
	
		let errors;
		try {
			if (JOINT_TAX() && userSegment === "erhverv") {	
				await AuthHelper.register(data);
				await AuthHelper.authenticate(data, null, null, null, false);
	
				this.setState({ signupDone: true, modalOpen: true });
			} else {
				let redirection = '/';
				await AuthHelper.register(data);
				await AuthHelper.authenticate(data, this.props.history, redirection, null, true);
	
				this.setState({ signupDone: true });
			}
	
			const redirection = getParam('redirect');
			googleTagManager.pushRegisterNewUser(redirection || "none", data.type);
		} catch (e) {
			errors = e;
			this.setState({
				errors: errors || {},
			});
		} finally {
			this.setState({ isFetching: false });
		}
	};

	getUserSegment = () => getParam(SEGMENT) || [PRIVAT, AKADEMI].find(segment => this.props.location.pathname.endsWith(segment)) || ERHVERV;

	validateFormInput = () => {
        const { isFetching } = this.state;
        if (isFetching) {
            return true;
        }

		const userData = {
			type: this.getUserSegment(),
			...this.state.formValues,
		};
	
		return AuthHelper.hasErrors(userData, this.getRelevantFormProperties());
	};

	goTo = to => {
		this.props.history.push({
			pathname: to,
			search: this.props.location.search,
		});
	};

	onFieldChange = (_, event ) => {
		const { formValues } = this.state;
		if (event) {
		  const { name, value, checked } = event;
		  formValues[name] = value !== undefined ? value : checked;
		}
		this.setState({ formValues });
	  };

	validateFormValues = () => {
		const errors = AuthHelper.hasErrors(this.state.formValues) || {};
		const correct = new Set();
		for (let key of Object.keys(this.state.formValues)) {
			if (!errors[key]) {
				correct.add(key);
			}
		}
		this.setState({ errors, correct });
	};

	onSubmit = async e => {
		e && e.preventDefault && e.preventDefault();
		const { formValues, invitationToken, gptToken,} = this.state;
		
		const { erpCredentials, referrer } = formValues;
		const validationErrors = this.validateFormInput();
		if (validationErrors) {
			this.setState({ errors: validationErrors });
			return;
		}
	
		// collect all the data that the user was prompted to fill out
		const data = {
			type: this.getUserSegment(),
		};
	
		for (let property of this.getRelevantFormProperties()) {
			data[property] = formValues[property];
		}
	
		// add referrer, if present
		referrer && (data.referrer = referrer);
	
		// add ERP credentials, if present
		erpCredentials && (data.erpCredentials = erpCredentials);
	
		// add invitation token, if present
		invitationToken && (data.invitationToken = invitationToken);
	
		// add GPT (general purpose token), if present (in this context, GPT authorizes academy users to registration)
		gptToken && (data.gptToken = gptToken);
		
		try {
		this.handleRegister(data);

		} catch (error) {
			this.setState({ errors: { other: error.message } });
		}
	};

	getRegisterText = () => {
		const userSegment = this.getUserSegment();
		
		const registerSuffixText = {
			[PRIVAT]: 'boligudlejer',
			[ERHVERV]: 'virksomhed',
		}[userSegment] || 'bruger';

		return `Opret ${registerSuffixText}`;
	};

	renderOwnerShipModal = () => {
		const { modalOpen } = this.state;

		return (
		  <OwnerShipModal
			openModal={modalOpen}
			closeModal={() => this.setState({ modalOpen: false })}
			name={this.state.formValues.name}
			history={this.props.history}
			location={this.props.location}
			cvr={this.state.formValues.cvr}
			loginEmail={this.state.formValues.email}
		  />
		);
	  }
	

	renderSignupSuccessMessage = () => {
		return (
			<>
				<div className={styles.successMessage}>
					<div>
						<span>{i18n.RegisterForm.successMsg}</span>
						<br />
						<span>
							{i18n.RegisterForm.redirectNormalMsg}
						</span>
					</div>
				</div>
			</>
		);
	};

	renderHeader = () => {
		let title;
		let subtitle;

		if (this.state.invitationToken) {
			title = 'Invitation';
			subtitle = `Tilslut dig ${getParam('displayName')} på Digital Revisor`;
		} else {
			title = this.getRegisterText();
		}

		const segment = this.getUserSegment();
		const icon = this.segmentDescriptions[segment]?.icon;

		return (
			<>
				<Header icon={icon} content={title} />
				{subtitle && <div>{subtitle}</div>}
			</>
		);
	};

	renderSegmentSelector = () => {
		if (!this.isSegmentSelectorActive()) {
			return null;	
		}

		const userSegment = this.getUserSegment();

		return (
			<Segment basic secondary style={{ borderRadius: '4px', border: '1px solid lightgray' }}>
				<div style={{ padding: '0.33em' }}>
					<Grid columns={2}>
						{[PRIVAT, ERHVERV].map(segment => (
							<SegmentChoice
								content={this.segmentDescriptions[segment].title}
								icon={this.segmentDescriptions[segment].icon}
								active={userSegment === segment}
								onClick={() => this.goTo(`/register/${segment}`)}
							/>
						))}
					</Grid>
				</div>
			</Segment>
		);
	}

	renderFooter = () => {
		const { search } = this.props.location;

		if (this.state.signupDone) {
			return null;
		}

		return (
			<>
				<Button
					content={this.getRegisterText()}
					onClick={this.onSubmit}
					disabled={this.state.isFetching}
					loading={this.state.isFetching}
					fluid
					primary
				/>
				<div style={{ textAlign: 'center', marginTop: '1em' }}>
					Har du allerede en bruger?&nbsp;
                    <ColoredText
                        color='green'
                        content='Hop til login'
                        link={`/login${search}`}
                    />
				</div>
			</>
		);
	};

	renderContent = () => {
		if (this.state.signupDone) {
			return <div>
				{this.renderSignupSuccessMessage(this.state.ownerShip)}
			</div>;
		}

		return (
			<>
				{this.renderSegmentSelector()}
				<Switch>
					{[PRIVAT, ERHVERV, AKADEMI].map(segment => (
						<Route
							path={`/register/${segment}`}
							render={
								() => <RegisterForm
									userSegment={segment}
									relevantProperties={this.getRelevantFormProperties()}
									invitation={this.state.invitationToken}
									errors={this.state.errors}
									values={this.state.formValues}
									correct={this.state.correct}
									onFieldChange={this.onFieldChange}
									onSubmit={this.onSubmit}
									validateFormValues={this.validateFormValues}
									setIsPerformingIntegration={isPerformingIntegration => this.setState({ isPerformingIntegration })}
								/>
							}
						/>
					))}
					<Redirect
						exact
						path='/register*'
						to={{
							pathname: `/register/${PRIVAT}`,
							search: this.props.location.search,
						}}
					/>
				</Switch>
				{
					this.state.errors?.other &&
					<Message
						error
						content={this.state.errors.other}
						icon='warning circle'
					/>
				}
			</>
		);
	};


	

	render = () => {

		return (
		  <HeroForm>
			{this.renderHeader()}
			{this.state.signupDone ? (
			  <>
				{this.renderOwnerShipModal()}
				{this.renderSignupSuccessMessage()}
			  </>
			) : (
			  <>
				{this.renderContent()}
				{this.renderFooter()}
			  </>
			)}
		  </HeroForm>
		);
	  };
}

export default withRouter(Register);
