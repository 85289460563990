import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    wantsNews: i18n({
        [daDK]: 'Du vil modtage nyheder om lovændringer, rabatter, viden, frister og nye tiltag der er relevante for din specifikke bruger.',
        [enUS]: 'You will receive news about change of laws, discount campaigns, knowledge, and more related to your specific user.',
    }),
    wantsStatusMails: i18n({
        [daDK]: (
            'Du vil modtage mails vedrørende vigtige frister og eventuelle påmindelser, ' +
            'hvis vi kan se, at du mangler at indberette til Skat og/eller Erhvervsstyrelsen. ' +
            'Du vil ikke modtage mails om nye tiltag, rabatter eller mails af anden kommerciel karakter.'
        ),
        [enUS]: 'Receive mails regarding deadlines related to your user.',
    }),
};