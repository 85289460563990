import set from 'lodash.set';
import get from 'lodash.get';
import { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Input, Loader, Popup, Table } from 'semantic-ui-react';
import { formatNumber } from 'util/format/Number';
import * as productEngine from 'http/productEngine';
import * as fileStorage from 'http/file-storage'
import Tooltip from 'design/atoms/Tooltip';
import NumberInput from 'design/atoms/NumberInput';
import FileAttachment from 'design/molecules/FileAttachmentWidget';
import { createCleanResource } from './FactWidgets/resource';

import styles from './ResourceTable.module.scss';

const TableRowFileuploadTrigger = ({ productFiles, fileUploadKey, onFilesChange }) => {
    return (
        <FileAttachment
            fileUpload={true}
            files={productFiles}
            fileUploadID={fileUploadKey}
            onFilesChange={onFilesChange}
            renderTrigger={({ files }, setModalOpen) => {
                const amountOfFilesBadge = files.length > 0 && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: '-0.65em',
                            right: '-0.25em',
                            fontSize: '50%',
                            backgroundColor: 'black',
                            color: 'white',
                            width: '12px',
                            height: '12px',
                            pointerEvents: 'none',
                            opacity: 0.75,
                        }}
                    >
                        {files.length}
                    </div>
                );

                return (
                    <div style={{ display: 'inline-block', position: 'relative' }}>
                        {amountOfFilesBadge}
                        <Popup
                            position='left center'
                            content={`${files.length} fil${files.length !== 1 ? 'er' : ''} vedhæftet`}
                            trigger={
                                <Icon
                                    name='attach'
                                    link
                                    onClick={() => setModalOpen(true)}
                                />
                            }
                        />
                    </div>
                );
            }}
        />
    );
};

const TableColumnInputComponent = ({ tableColumn, onChange, defaultValue }) => {
    const { dataType, header } = tableColumn;

    const placeholder = `${header}...`;

    switch (dataType) {
        case 'number': {
            return (
                <NumberInput
                    className={styles.borderlessInput}
                    onChange={value => onChange(Number(value))}
                    value={defaultValue}
                    placeholder={placeholder}
                    fluid
                />
            );
        }

        case 'string': {
            return (
                <Input
                    className={styles.borderlessInput}
                    onChange={(_, { value }) => onChange(value)}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    fluid
                />
            );
        }

        case 'enumString': {
            return (
                <Dropdown
                    selection
                    search
                    fluid
                    style={{ border: 'none' }}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={(_, { value }) => onChange(value)}
                    options={tableColumn.questionOptions.map(({ optionText, optionValue, optionTooltip }) => {
                        return {
                            text: optionText,
                            value: optionValue,
                            description: optionTooltip,
                        };
                    })}
                />
            );
        }

        default: {
            return null;
        }
    }
};

const makeFileuploadKey = (userResourceSlug, tableID) => {
    return `${userResourceSlug}::${tableID}`; 
};

const ResourceTable = ({ field, values, onChange, productMetadata, files, onFilesChange, productLabel, reloadProductData }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userResources, setUserResources] = useState([]);

    const { table, resourceTemplateSlug, resourceNodeID } = field;

    const getResources = useCallback(() => {
        return productEngine.getResources(resourceTemplateSlug, productMetadata.id);
    }, [resourceTemplateSlug, productMetadata.id]);

    const addResource = async () => {
        const clean = createCleanResource();
        await productEngine.upsertResource(resourceTemplateSlug, clean, productMetadata.id);
        const fromServer = await getResources();
        setUserResources(fromServer);
    };

    const deleteResource = async resourceSlug => {
        const fileUploadKey = makeFileuploadKey(resourceSlug, table.id);
        const filesAssociatedWithResource = files[fileUploadKey] || [];
        const actionID = productMetadata.facts[field.resourceNodeID].supplier;

        await Promise.all([
            Promise.all(filesAssociatedWithResource.map(file => fileStorage.deleteFile(file.id))),
            productEngine.runAction(productMetadata.id, productLabel, actionID, { slugOfResourceToDelete: resourceSlug }),
        ]);

        const [userResources] = await Promise.all([
            getResources(),
            reloadProductData(),
        ]);

        setUserResources(userResources);
    };

    useEffect(() => {
        getResources()
            .then(userResources => setUserResources(userResources))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [getResources]);

    const colSpan = table.columns.length + 1;

    const resourcesData = get(values, `${resourceNodeID}.value.resourcesData`, {});

    return (
        <Table compact='very' celled>
            <Table.Header>
                <Table.Row>
                    {table.columns.map(column => {
                        return (
                            <Table.HeaderCell>
                                {column.header}{' '}
                                <Tooltip
                                    triggerOverwrite={<Icon color='grey' name='question circle' />}
                                />
                            </Table.HeaderCell>
                        );
                    })}
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {loading && (
                    <Table.Row>
                        <Table.Cell colSpan={colSpan} textAlign='center'>
                            <Loader inline active />
                            <br />
                            Indlæser...
                        </Table.Cell>
                    </Table.Row>
                )}

                {error && (
                    <Table.Row>
                        <Table.Cell colSpan={colSpan} textAlign='center' error>
                            <Icon name='warning sign' /> Data kunne ikke indlæses...
                        </Table.Cell>
                    </Table.Row>
                )}

                {userResources.map(userResource => {
                    const fileUploadKey = makeFileuploadKey(userResource.slug, table.id);

                    return (
                        <Table.Row key={userResource.slug}>
                            {table.columns.map(column => {
                                let componentToShow;
                                let cellBackgroundColor;

                                if (column.isQuestion) {
                                    const valueMap = get(values, `${column.questionFactID}.value`, { values: {}, lastYearValues: {} });
                                    const defaultValue = get(valueMap, `values.${userResource.slug}`);

                                    componentToShow = (
                                        <TableColumnInputComponent
                                            tableColumn={column}
                                            onChange={value => {
                                                set(valueMap, `values.${userResource.slug}`, value);
                                                onChange(column.questionFactID, valueMap);
                                            }}
                                            defaultValue={defaultValue}
                                        />
                                    );
                                } else {
                                    // not a question, treat as calculation
                                    const value = get(resourcesData, `${userResource.slug}.calculationResults.${column.tag}`, 0);
                                    cellBackgroundColor = 'rgb(250, 250, 250)';
                                    componentToShow = (
                                        <span style={{ padding: '1em 1em' }}>
                                            {(
                                                Math.abs(Number(value)) < 10
                                                    ? new Intl.NumberFormat('da-DK').format(value)
                                                    : formatNumber(value)
                                            )}
                                        </span>
                                    );
                                }

                                return (
                                    <Table.Cell
                                        textAlign='right'
                                        style={{ padding: 0, background: cellBackgroundColor }}
                                        content={componentToShow}
                                    />
                                );
                            })}
                            <Table.Cell textAlign='right' width={1} style={{ background: 'rgb(250, 250, 250)' }}>
                                {table.allowFileuploadPerRow && (
                                    <TableRowFileuploadTrigger
                                        productFiles={files}
                                        fileUploadKey={fileUploadKey}
                                        onFilesChange={onFilesChange}
                                    />
                                )}
                                <Icon
                                    name='trash'
                                    link
                                    onClick={() => deleteResource(userResource.slug)}
                                />
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan={colSpan} textAlign='right'>
                        <Button onClick={addResource} content='Tilføj' icon='plus' />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};

export default ResourceTable;