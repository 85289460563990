import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Header, List } from 'semantic-ui-react';
import { searchCompanies, getCompanyRelations } from '../../../http/cvr';
import { createOwnerShipEntity } from '../../../http/accounts';
import { toast } from 'react-toastify';

const OwnerShipModal = ({ openModal, closeModal, history, location, cvr, loginEmail, header }) => {

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [ownerShip, setOwnerShip] = useState([]);
  const [ownerName, setOwnerName] = useState('');

  const daughterIcon = 'building';
  const ownerIcon = 'group';
  let defaultText =
   `Vi fandt nogle selskaber som hænger sammen med ${ownerName}.
   Vil du også tilføje dem?`;

  useEffect(() => {
    const fetchOwnerName = async () => {
      const response = await searchCompanies(cvr);
      setOwnerName(response[0].name);
    }

    const fetchRelations = async () => {
      const relations = await getCompanyRelations(cvr);
      const filteredDaughterCompanies = filterCompanies(relations.daughterCompanies || [], cvr);
      const filteredOwners = filterCompanies(relations.owners || [], cvr);

      if (filteredDaughterCompanies.length === 0 && filteredOwners.length === 0) {
        closeModal();
        history.push('/');
      } else {
        setOwnerShip({
          daughterCompanies: filteredDaughterCompanies,
          owners: filteredOwners
        });
      }
    };

    fetchRelations();
    fetchOwnerName();
  }, [cvr, closeModal, history]);

  const handleCompanyClick = (company) => {
    if (selectedCompanies.includes(company)) {
      setSelectedCompanies(selectedCompanies.filter((c) => c !== company));
    } else {
      setSelectedCompanies([...selectedCompanies, company]);
    }
  };

  const handleSubmit = async () => {
    try {
      await Promise.all(
        selectedCompanies.map((company) =>
          createOwnerShipEntity(loginEmail, company)
        )
      );

      const redirection = new URLSearchParams(location.search).get('redirect');
      history.push(redirection || '/');
    } catch (error) {
      toast.error(error.message || 'Der opstod en fejl');
    }
  };

  const handleClose = () => {
    setSelectedCompanies([]);
    closeModal();
    history.push('/');
  };

  const companyCVR = (company) => company.CVR || company.CVR;

  const createList = (companies, defaultIcon) => (
    <List selection>
      {companies.map((company) => {
        const isSelected = selectedCompanies.includes(company);
        return (
          <List.Item
            key={companyCVR(company)}
            onClick={() => handleCompanyClick(company)}
          >
            <List.Icon
              verticalAlign='middle'
              name={isSelected ? 'check circle' : defaultIcon}
              color={isSelected ? 'green' : 'grey'}
            />
            <List.Content>
              <List.Header as={'h4'}>{company.name}</List.Header>
              <List.Description>{companyCVR(company)}</List.Description>
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );

  const filterCompanies = (companies, cvr) => {
    return companies.filter(company => {
      const companyCVRValue = String(company.CVR);
      const modalCVRValue = String(cvr);
      return companyCVRValue !== modalCVRValue;
    });
  };

  return (
    <Modal open={openModal} size='tiny'>
      <Header>
        {header || defaultText}
      </Header>
      <Modal.Content scrolling>
        <Form>
          {ownerShip.daughterCompanies && ownerShip.daughterCompanies.length > 0 && (
            <>
              <Header as='h3'>Datter selskaber</Header>
              {createList(ownerShip.daughterCompanies, daughterIcon)}
            </>
          )}
          {ownerShip.owners && ownerShip.owners.length > 0 && (
            <>
              <Header as='h3'>Legale ejere</Header>
              {createList(ownerShip.owners, ownerIcon)}
            </>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Nej tak</Button>
        <Button disabled={selectedCompanies.length === 0} primary onClick={handleSubmit}>Tilføj</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default OwnerShipModal;